<template>
  <BeforeLogin>
    <div class="login">
      <p class="title">登录</p>
      <div style="position: relative;">
        <a-input size="large" placeholder="请输入手机号" v-model="userName" ref="userNameInput" @input="userNameInput">
          <img src="../assets/icon/phone.png" class="icon" slot="prefix" />
        </a-input>
        <p class="error" v-show="userNameError">请输入手机号</p>
      </div>
      <div style="position: relative;">
        <a-input-password size="large" placeholder="请输入密码" type="password" v-model="password" ref="passwordInput"
          @pressEnter="login" @input="passwordInput">
          <img src="../assets/icon/password.png" class="icon" slot="prefix" />
        </a-input-password>
        <p class="error" v-show="passwordError">请输入6-18位登录密码密码</p>
      </div>
      <div class="center">
        <!-- <a-checkbox v-model="remeber">记住密码</a-checkbox> -->
        <div class="right" @click='$router.push("/forgetPassword");'>忘记密码</div>
      </div>
      <button class="default-btn" @click="login">
        <a-icon type="loading" v-show="loading" />
        <span v-show="!onLine">离线登录</span>
        <span v-show="onLine">登录</span>
      </button>
      <button class="default-btn login2" @click="login2" v-show="onLine">之江汇快捷登录（教师）</button>
      <p class="bottom">
        新用户请先注册
        <span class="black" @click="$router.push('/loginRegistration?loginStep=0')">立即注册 ></span>
      </p>
      <a-modal :visible="visible" :footer="null" :closable="false" :width="392">
        <div class="completed">
          <img src="../assets/img_login_message.png" />
          <p>您的个人信息不完善，请先完善个人信息。</p>
          <button class="default-btn" @click="$router.push('complete')">确定</button>
        </div>
      </a-modal>
    </div>
  </BeforeLogin>
</template>
<script>
import md5 from "blueimp-md5";
import BeforeLogin from "../components/BeforeLogin.vue";
import { login, getLoginUrl, completed } from "../api/user"
import { mapMutations } from "vuex";
import Cookies from 'js-cookie'

export default {
  name: "login",
  components: { BeforeLogin },
  data() {
    return {
      userName: null,
      password: null,
      remeber: false,
      visible: false,
      passwordError: false,
      userNameError: false,
      loading: false,
      src: ''
    };
  },
  computed: {
    onLine() {
      return !this.offline
    }
  },
  props: ["changeForm", "offline", "zjer"],
  mounted: function () {
    //localStorage.user = ''
    if (localStorage.userName) {
      this.userName = localStorage.userName;
      this.password = localStorage.password;
      this.remeber = true
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    userNameInput(e) {
      let value = e.target.value
      let reg = /^1[3456789]\d{9}$/
      this.userNameError = !reg.test(value)
    },
    passwordInput(e) {
      let value = e.target.value
      let reg = /^.{6,18}$/
      this.passwordError = !reg.test(value)
    },
    async login() {
      if (this.userNameError || this.passwordError || this.loading) return
      if (this.userName && this.password) {
        this.loading = true
        const params = {
          userName: this.userName,
          password: md5(this.password).toUpperCase(),
        }
        try {
          const res = await login(params)
          localStorage.user = await JSON.stringify(res.data);
          console.log('localStorage.user', JSON.parse(localStorage.user));
          this.setUser(res.data);
          localStorage.lastUser = JSON.stringify({
            userName: this.userName,
            password: this.password
          })
          if (this.remeber) {
            localStorage.userName = this.userName;
            localStorage.password = this.password;
          } else {
            localStorage.userName = "";
            localStorage.password = "";
          }
          const completedres = await completed(res.data.userId)
          if (!completedres.data) {
            this.visible = true;
          } else {
            console.log(location.href)
            // this.$router.push("/");
            Cookies.set('commonName', localStorage.user, { expires: 7, domain: 'zjeav.com' })
            if (location.href.indexOf("zjeav.xht.com") >= 0) {
              location.href = 'http://zs-platform.100fen.ltd/#/videoResource' // 测试环境
            } else if (location.href.indexOf("pre-zjeav.zjeav.com") >= 0) {
              location.href = 'http://zs-platform-staging.yunzuoye.net//#/videoResource' //预发环境
            } else if (location.href.indexOf("www.zjeav.com") >= 0 || location.host === 'zjeav.com') {
              location.href = 'https://zjeav.com/#/' //生产环境
            }
            // location.href = 'https://zjy.zjeav.com/#/videoResource' // 跳转到新官网音像教材
            // location.href = 'http://zs-platform.100fen.ltd/#/videoResource' // 测试环境
            // location.href = 'http://localhost:8081/#/videoResource'
            this.loading = false
          }
        } finally {
          this.loading = false
        }



        // login({
        //   userName: this.userName,
        //   password: md5(this.password).toUpperCase(),
        // })
        //   .then(async res => {
        //     if (res) {
        //       localStorage.lastUser = JSON.stringify({
        //         userName: this.userName,
        //         password: this.password
        //       })
        //       localStorage.user = await JSON.stringify(res.data);
        //       console.log('localStorage.user', JSON.parse(localStorage.user));
        //       this.setUser(res.data);
        //       if (this.remeber) {
        //         localStorage.userName = this.userName;
        //         localStorage.password = this.password;
        //       } else {
        //         localStorage.userName = "";
        //         localStorage.password = "";
        //       }
        //       completed(res.data.userId).then(res => {
        //         if (!res.data) {
        //           this.visible = true;
        //         } else {
        //           console.log(location.href)
        //           // this.$router.push("/");
        //           Cookies.set('commonName', localStorage.user, { expires: 3, domain: 'zjeav.com' })
        //           if (location.href.indexOf("zjeav.xht.com") >= 0) {
        //             location.href = 'http://zs-platform.100fen.ltd/#/videoResource' // 测试环境
        //           } else if (location.href.indexOf("pre-zjeav.zjeav.com") >= 0) {
        //             location.href = 'https://zs-platform-staging.yunzuoye.net//#/videoResource' //预发环境
        //           } else if (location.href.indexOf("www.zjeav.com") >= 0 || location.host === 'zjeav.com') {
        //             location.href = 'https://zjeav.com/#/' //生产环境
        //           }
        //           // location.href = 'https://zjy.zjeav.com/#/videoResource' // 跳转到新官网音像教材
        //           // location.href = 'http://zs-platform.100fen.ltd/#/videoResource' // 测试环境
        //           // location.href = 'http://localhost:8081/#/videoResource'
        //           this.loading = false
        //         }
        //       }).catch(e => {
        //         this.loading = false
        //       });
        //     }
        //   })
        //   .catch(e => {
        //     console.log(e);
        //     this.loading = false
        //   });
      }
    },
    login2() {
      this.webview = document.querySelector('webview');
      getLoginUrl().then((res) => {
        location.href = res.data;
      })
    }
  },
};
</script>
<style scoped lang="less">
.log {
  height: calc(~"100% - 80px");
  display: flex;

  &_logo {
    width: 730px;
    height: 100%;
    background: url("../assets/login_img@2x.png");
    background-size: 100% 100%;
  }

  .content {
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
  }

  /deep/ .ant-input {
    height: 48px;
  }
}

.completed {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 173px;
    height: 173px;
  }

  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .default-btn {
    width: 224px;
    margin-top: 40px;
  }
}

.login {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 360px;

  .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    margin-bottom: 32px;
    font-weight: 600;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  /deep/ .ant-input-affix-wrapper {
    margin-bottom: 32px;
  }

  .error {
    font-size: 12px;
    color: #ed4014;
    position: absolute;
    bottom: 0;
  }

  .center {
    margin-bottom: 10px;

    .right {
      float: right;
      cursor: pointer;
    }

    /deep/ .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #f73210;
      border-color: #f73210;
    }
  }

  p.bottom {
    font-size: 16px;
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.45);

    .black {
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      font-weight: 600;
    }

    .right {
      float: right;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.default-btn {
  &.login2 {
    border: 1px solid #f73210;
    background: white;
    color: #f73210;
    width: 360px;
    bottom: 150px;
    margin-top: 16px;
  }
}

.default-btn {
  height: 48px;
  background: #f73210;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  -webkit-app-region: no-drag;
}
</style>
