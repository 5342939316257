<template>
  <a-modal
      :title="record?'反馈详情':'提交反馈'"
      :visible="visible" :maskClosable="!!record"
      :confirm-loading="confirmLoading"
      destroyOnClose
      @cancel="cancel"
      :footer="null"
  >
    <a-form-model :rules="rules" style="width: 100%;" ref="ruleForm" :model="form" :label-col="{ span: 4 }"
                  :wrapper-col="{ span: 20 }">
      <a-form-model-item label="反馈类型" prop="feedbackType">
        <a-select placeholder="请选择反馈类型" v-model="form.feedbackType" :disabled="!!record">
          <a-select-option v-for="item of types" :key="item.feedbackType">{{item.name}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="反馈内容" prop="feedbackDescription">
        <a-textarea v-model="form.feedbackDescription" :rows="4" :disabled="!!record"  placeholder="请输入问题的描述" :maxLength="140"></a-textarea>
<!--        <a-upload-->
<!--            name="avatar"-->
<!--            list-type="picture-card"-->
<!--            class="avatar-uploader"-->
<!--            :show-upload-list="false"-->
<!--            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"-->
<!--            @change="handleChange"-->
<!--        >-->
<!--          <img v-if="form.imageUrl" :src="form.imageUrl" alt="avatar" />-->
<!--          <div v-else>-->
<!--            <a-icon type="plus" />-->
<!--            <div class="ant-upload-text">-->
<!--              上传-->
<!--            </div>-->
<!--          </div>-->
<!--        </a-upload>-->
      </a-form-model-item>
<!--      <a-form-model-item label="联系方式" prop="name">-->
<!--        <a-input v-model="form.name"  placeholder="QQ/电话/邮箱，方便我们与您联系" :maxLength="30"></a-input>-->
<!--      </a-form-model-item>-->
    </a-form-model>
    <div class="btns" v-if="!record">
      <a-button class="btn cancel"  @click="cancel">我在想想</a-button>
      <a-button class="btn ok" @click="ok" :loading="confirmLoading">提交反馈</a-button>
    </div>
  </a-modal>
</template>
<script >
import {addFeedBack, getFeedBackType} from "@/api/profile";
import {mapGetters} from "vuex";

export default   {
  data(){
    return {
      visible :false,
      confirmLoading : false,
      resolve:null,
      reject:null,
      form: {
      },
      rules: {
        feedbackType: [{ required: true, message: '请选择类型' }],
        feedbackDescription: [{ required: true, message: '请输入反馈内容' }],
      },
      record: null,
      types: []
    }
  },
  created() {
    this.getTypes()
  },
  methods:{
   async getTypes(){
     const res = await getFeedBackType()
     this.types = res.data
     this.$emit('getTypes', res.data)
    },
    show(record) {
      this.record = record
      if(record){
        this.form.feedbackType = record.feedbackType
        this.form.feedbackDescription = record.feedbackDescription
      }else{
        this.form = {
          feedbackType: void 0,
          feedbackDescription: void 0
        }
      }
      this.visible = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleChange(){

    },
    ok:async function (){
      await this.$refs.ruleForm.validate()
      this.confirmLoading = true
      try {
        await addFeedBack({...this.form, userId: this.user.userId})
        this.confirmLoading = false
        this.resolve()
        this.visible = false
      }catch (e) {
        this.confirmLoading = false
      }
    },

    cancel() {
      this.visible = false
      this.reject()
    }
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
  }
}
</script>

<style scoped lang="less">
.btns{
  display: flex;
  justify-content: flex-end;
}
.btn{
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #f73210;
  border-radius: 4px;
  background: #f73210;
  color: #fff;
  cursor: pointer;
  width: 128px;
}
.btn.cancel{
  background-color: #fff;
  color: #f73210;
  margin-right: 32px;

}
.avatar-uploader{
  margin-top: 24px;
  width: 160px;
  height: 160px;
  /deep/ .ant-upload.ant-upload-select-picture-card{
    width: 160px;
    height: 160px;
  }
}
</style>