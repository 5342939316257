<template>
      <div class=" foget">
        <a-form :form="form" class="edit">
          <div class="inputPhone">
            <a-form-item :label="'手机号'">
              <a-input
                @change="$refs.getSmsbutton.changeVisible(false)"
                placeholder="请输入手机号"
                v-decorator="[
                  'mobile',
                  {
                    trigger: 'blur',
                    rules: [
                      { required: true, message: '请输入手机号!' },
                      { message: '请输入正确手机号!', pattern: /^1[3456789]\d{9}$/ }
                    ]
                  }
                ]"
              >
                <img src="../assets/icon/phone.png" class="icon" slot="prefix" />
              </a-input>
              <GetSmsbutton :validateFields="form.validateFields" usage="2" ref="getSmsbutton" />
            </a-form-item>
          </div>
          <a-form-item :label="'验证码'">
            <a-input
              placeholder="请输入手机验证码"
              v-decorator="[
                'token',
                {
                  trigger: 'blur',
                  rules: [
                    { required: true, message: '请输入验证码!' },
                    { message: '请输入验证码!', pattern: /\d{4}$/ }
                  ]
                }
              ]"
            >
              <img src="../assets/icon/captcha.png" class="icon" slot="prefix" />
            </a-input>
          </a-form-item>
          <a-form-item :label="'密码'">
            <a-input-password
              placeholder="设置6-18位登录密码"
              v-decorator="[
                'newPassword',
                {
                  rules: [
                    { required: true, message: '请输入登录密码!' },
                    { message: '请设置6-18位登录密码!', min: 6, max: 18 },
                    {
                      validator: validateToNextPassword
                    }
                  ]
                }
              ]"
              type="password"
            >
              <img src="../assets/icon/password.png" class="icon" slot="prefix" />
            </a-input-password>
          </a-form-item>
          <a-form-item :label="'确认密码'">
            <a-input-password
              placeholder="请再次输入密码"
              v-decorator="[
                'confirmPassword',
                {
                  rules: [
                    { required: true, message: '请再次输入密码!' },
                    ,
                    {
                      validator: compareToFirstPassword
                    }
                  ]
                }
              ]"
              type="password"
              @blur="handleConfirmBlur"
            >
              <img src="../assets/icon/password.png" class="icon" slot="prefix" />
            </a-input-password>
          </a-form-item>
        </a-form>
        <button class="default-btn" @click="submit"><a-icon type="loading" v-show="loading" />提交</button>
      </div>
</template>
<script>
import md5 from 'blueimp-md5'
import BeforeLogin from '../components/BeforeLogin.vue'
import GetSmsbutton from '../components/getSmsbutton.vue'
import { restPassword } from '../api/user'
import userLayout from '../components/userLayout.vue'
import Footer from '@/components/Footer'
export default {
  name: 'forgetPassword',
  components: { BeforeLogin, GetSmsbutton, userLayout, Footer },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'forget' }),
      loading: false
    }
  },
  computed: {
    onLine() {
      return !this.offline
    }
  },
  mounted: function () {
    //localStorage.user = ''
    if (localStorage.userName) {
      this.userName = localStorage.userName
      this.password = localStorage.password
      this.remeber = true
    }
  },
  methods: {
    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('newPassword')) {
        callback('两次输入的密码不一致!')
      } else {
        callback()
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirmPassword'], { force: true })
      }
      callback()
    },
    submit() {
      if (this.loading) return
      this.form.validateFields((err, value) => {
        if (!err) {
          this.loading = true
          value.newPassword = md5(value.newPassword).toUpperCase()
          restPassword({ ...value })
            .then(() => {
              this.$message.success('修改成功！')
              this.loading = false
              this.$router.push('/loginRegistration?loginStep=1')
            })
            .catch(e => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
<style scoped lang="less">

.foget {
  min-height: 428px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    margin-bottom: 32px;
    font-weight: 600;
  }
  .inputPhone {
    .ant-input-affix-wrapper {
      width: 232px;
    }
    button {
      height: 48px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      margin-left: 12px;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
  }
  p.bottom {
    font-size: 16px;
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.45);
    .black {
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      font-weight: 600;
    }
    .right {
      float: right;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  /deep/ .ant-form-item-label {
    width: 80px;
    vertical-align: top;
  }
  /deep/ #forget_mobile {
    width: 220px;
  }
  /deep/ .ant-form-item-control-wrapper {
    display: inline-block;
    input {
      height: 48px;
      width: 350px;
    }
  }
}
.default-btn {
  width: 360px;
  margin-left: 80px;
  height: 48px;
  background: #f73210;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  -webkit-app-region: no-drag;
}
</style>