<template>
  <!-- 音像教材-音视频在线学习 -->
  <div class="onlineLearning-container">
    <!-- 左侧目录 -->
    <div class="onlineLearning-left">
        <header>{{this.$route.query.bookName}}</header>
        <p class="onlineLearning-title">
          <img src="@/assets/video/resource.svg" alt="" />
          目录
        </p>
      <Deep ref="folderTree" :showEditing="showEditing" :folderList="folderList" @selectTree="selectTree"
        :defaultExpandedKeys="defaultExpandedKeys" :selectedTree="selectedTree" :key-name="`catalogId`"
        :deep-leafs.sync="selectDeepLeafs" iconType="catalog"></Deep>
    </div>

    <!-- 右侧音视频 -->
    <div class="onlineLearning-right" v-show="isLeaf">
      <OnlineVideo :hideDownload="!mustFlag"  :list="list" @next="onNext" :audioName="audioName"></OnlineVideo>
    </div>
    <!-- 音视频无资源时占位 -->
    <div class="onlineLearning-right onlineLearning-empty" v-show="!isLeaf">
      <template>
        <a-empty>
          <span slot="description">请先选择左侧章节</span>
        </a-empty>
      </template>
    </div>
  </div>
</template>

<script>
import { getBookResourceById, getCatalogResource, updatePlayCount,userConfig } from '@/api/videoResource';
import { arrayTreeAddLevel } from '@/util';
import Deep from '@/components/deep/index.vue'; // 目录组件
import OnlineVideo from '@/components/onlineLearning.vue';
import { buryingPoint, getPoints } from "@/api/prize"; // 音视频播放组件

function flattenArray(arr) {
  let flattened = [];

  arr.forEach((item) => {
    if (item.childList?.length) {
      flattened = flattened.concat(flattenArray(item.childList));
    } else {
      flattened.push(item);
    }
  });

  return flattened;
}

function findNextNode(id, list) {
  const f = flattenArray(list)
  let index = f.findIndex(item=>item.catalogId === id)
  return f[index+1] || f[0]
}



export default {
  name: 'VideoLearning',
  components: {
    Deep,
    OnlineVideo
  },
  data() {
    return {
      folderList: [], //树状图数据
      defaultExpandedKeys: [], // 默认选择打开的节点
      selectedTree: [], //当前选择的节点
      selectDeepLeafs: [], //
      showEditing: false, //是否能编辑
      isLeaf: false, //是否是叶子节点，默认为true时，当前选择的节点会被选择
      bookId: '', // 用来保存url上携带的bookID参数
      list: {}, // 给子组件传递的数据
      audioName: '',
      mustFlag:false,
      formData: {
        bookId: '', //书本id
        catalogIdList: [], //目录id集合
        categoryId: '', // 类别
        zjeavSourceId: '', //来源
        resourceType: '', // 资源类型 1:mp3 2:mp4
        name: '', // 搜索名字
        page: 1,
        size: 10,
        sortType: 2 // 排序
      },
      timer: {},
      num: 0
    };
  },

  created() {
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).userId;
    }
    this.bookId = this.$route.query.bookId;
    this.formData.bookId = this.bookId;
    this.getBookResourceListById();
    this.updatePlayCount();
    this.startTiming()
    userConfig({"configCode" : "resourceDownload",bookId:this.bookId}).then((res)=>{
			this.mustFlag = res.data.mustFlag
		})
  },
  async beforeDestroy() {
    clearInterval(this.timer)
    await this.sendBuryingPoint()
  },
  computed:{
    isPdf(){
      const resourceType = this.$route.query.resourceType
      return resourceType == '8'
    }
  },
  methods: {
    pdfInit(){
      if(this.isPdf&&this.folderList.length){
          const f = flattenArray(this.folderList)
          let find = f.find(item=>item.catalogId&&item.leafNodeType==1)

          if(find.parentCatalogId!=0){
            !this.defaultExpandedKeys.find(find=>find===find.parentCatalogId)&&
            this.$refs.folderTree.expand([...this.defaultExpandedKeys,find.parentCatalogId])
          }

          find&&this.selectTree(this.selectDeepLeafs = [find.catalogId], { node: { isLeaf: true } })
      }
    },
    onNext() {
      const currentCatalogId = this.selectedTree[0]
      let find = findNextNode(currentCatalogId,this.folderList)
      if(find.parentCatalogId!=0){
        !this.defaultExpandedKeys.find(find=>find===find.parentCatalogId)&&
        this.$refs.folderTree.expand([...this.defaultExpandedKeys,find.parentCatalogId])
      }
      const nextCatalogId = find.catalogId

      this.selectTree(this.selectDeepLeafs = [nextCatalogId], { node: { isLeaf: true } })
    },
    async sendBuryingPoint() {
      const userId = JSON.parse(localStorage.user)?.userId
      if(!userId || !this.bookId)return
      const currentDuration = await getPoints({
        userId,
        bookId: this.bookId,
        taskCode: 'EFFECTIVE_LEARNING'
      })
      const num = Number((this.num / 60).toFixed(2)) + currentDuration.data
      await buryingPoint({
        activityCode: localStorage.getItem('activityCode')|| 'E_STUDY',
        actionCode: 'PREVIEW_MATERIAL',
        userId,
        otherParams: `bookId#${this.bookId}@time#${num}`
      });
      this.num = 0
    },
    startTiming() {
      const that = this
      this.timer = setInterval(() => {
        ++that.num
        if (that.num >= 120) {
          that.sendBuryingPoint()
        }
        // console.log(this.num)
      }, 1000)
    },
    //音像教材的学习量，每次进入教材详情时请求
    updatePlayCount() {
      updatePlayCount({ userId: this.userId, bookId: this.bookId });
    },

    // 根据id查询目录信息
    async getBookResourceListById() {
      const { data } = await getBookResourceById({
        bookId: this.bookId
      });
      this.folderList = arrayTreeAddLevel(data);
      this.pdfInit()
    },

    selectTree(e, isLeaf) {
      this.selectedTree = e;
      this.folderList.forEach(element => {
        // console.log(element)
        // console.log(this.selectedTree[0])
        if (element.catalogId == this.selectedTree[0]) {
          // console.log(111)
          return (this.audioName = element.catalogName);
        } else if (element.childList) {
          element.childList.forEach(item => {
            // console.log(item)
            if (item.catalogId == this.selectedTree[0]) {
              return (this.audioName = item.catalogName);
            }
          });
        }
        // console.log(this.audioName)
      });

      // 通过v-if重制组件数据
      this.isLeaf = false;
      setTimeout(() => {
        this.isLeaf = isLeaf?.node.isLeaf;
      }, 1);
    }
  },
  watch: {
    selectedTree: async function () {
      this.formData.catalogIdList = this.selectedTree;
      const { data } = await getCatalogResource({ userId: this.userId, ...this.formData });
      if (data.list && data.list.length > 0) {
        this.list = data.list[0];
      }
    },
    deep: true
  }
};
</script>

<style lang="less" scoped>
.onlineLearning-container {
  min-height: 507px;
  width: 1200px;
  margin: 16px auto;
  display: flex;
  justify-content: left;
  // background-color: #fff;
  header {
    color: rgba(0,0,0,0.80);
    border-bottom: 1px solid  rgb(238,238,238);
    line-height: 28px;
    font-weight: 500;
    font-size: 20px;
    padding-bottom: 20px;
    word-break: break-all;
  } 
  .onlineLearning-left {
    width: 284px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 16px;
    padding: 16px;
    background-color: #fff;

    .ant-tree-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      white-space: normal !important;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      font-size: 14px;
    }

    .onlineLearning-title {
      font-family: PingFangSC-Medium;
      margin-bottom: 0;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 16px;
      vertical-align: bottom;
      padding: 20px 0;
      img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-bottom: 2px;
      }
    }

    .ant-tree.ant-tree-show-line li {
      padding: 4px 0;
    }
  }

  .onlineLearning-left::-webkit-scrollbar {
    display: none;
  }

  .onlineLearning-right {
    width: 900px;
    height: 507px;
    margin: 0 auto;
    background-color: #fff;
    position: fixed;
    top: 88px;
    left: 0;
    right: 0;
    transform: translateX(150px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .onlineLearning-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
  }
}
</style>
