<template>
    <div class="contactInformation">
        <div class="contactInformation-box" :class="homeType && 'boxbg'">
            <div class="contactInformation-box-content" :class="homeType && 'boxContent'" v-for="(item, index) in imgList" :key="index">
                <a-tooltip placement="left" :overlayClassName="homeType ? 'homeTooltip' : 'tooltip'">
                    <template slot="title">
                        <!-- <span v-if="!item.wxCode">{{ item.content }}</span> -->
                        <div v-if="item.wxCode" class="contactInformation-box-content_wxcode">
                            <div>{{ item.content }}</div>
                            <img :src="item.wxCode" alt="">
                        </div>
                    </template>
                    <div @click="item.action&&item.action()" class="contactInformation-box-content-box" :class="{'contentbox':homeType,'clickable':item.action} " @mouseenter="item.hoverType = true"
                        @mouseleave="item.hoverType = false">
                        <img v-if="!homeType" :src="item.hoverType ? item.imgHover : item.imgNormal" alt="">
                        <img v-else :src="item.hoverType ? item.homeImgHover : item.homeImgNormal" alt="">
                    </div>
                </a-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "contactInformation",
    data() {
        return {
            hoverType: false,
            imgList: [
                {
                    content: '在线客服',
                    action:()=>window.qimoChatClick(),
                    hoverType: false,
                    homeImgNormal: require('../assets/contactInformation/service_light_normal.svg'),
                    homeImgHover: require('../assets/contactInformation/service_light_hover.svg'),
                    imgNormal: require('../assets/contactInformation/service_normal.svg'),
                    imgHover: require('../assets/contactInformation/service_hover.svg')
                },
                {
                    content: 'QQ：4006851161',
                    action:()=>window.open('https://wpa1.qq.com/F63rgkqm?_type=wpa&qidian=true'),
                    hoverType: false,
                    homeImgNormal: require('../assets/contactInformation/qq_light_normal.svg'),
                    homeImgHover: require('../assets/contactInformation/qq_light_hover.svg'),
                    imgNormal: require('../assets/contactInformation/qq_normal.svg'),
                    imgHover: require('../assets/contactInformation/qq_hover.svg')
                },
                {
                    content: '扫码关注',
                    hoverType: false,
                    homeImgNormal: require('../assets/contactInformation/wechat_light_normal.svg'),
                    homeImgHover: require('../assets/contactInformation/wechat_light_hover.svg'),
                    imgNormal: require('../assets/contactInformation/wechat_normal.svg'),
                    imgHover: require('../assets/contactInformation/wechat_hover.svg'),
                    wxCode: require('../assets/contactInformation/wechatcode1.png')
                },
            ]
        }
    },
    methods: {
    },
    computed:{
      homeType(){
        return this.$route.meta.home
      }
    },
    mounted() {
        console.log(this.$route)
    }
}
</script>

<style lang="less">
.tooltip {
    .ant-tooltip-arrow::before {
        background-color: #fff;
    }

    .ant-tooltip-inner {
        background-color: #fff;
        color: #666;
    }
}
.homeTooltip{
     .ant-tooltip-arrow::before {
        background: rgba(255,255,255,0.2);
    }

    .ant-tooltip-inner {
        background: rgba(255,255,255,0.2);
        color: #FFFFFF;
    }
}
.boxbg {
    background: rgba(255,255,255,0.2) !important;
}
.contentbox{
    &:hover{
        background: rgba(255,255, 255, 0.2) !important;
    }
}
.clickable {
  cursor: pointer;
}
.boxContent{
    border-bottom: 1px solid rgba(255,255,255,0.2) !important;;
}
.boxContent:last-child {
    border-bottom: none !important;
}
.contactInformation {
    position: fixed;
    z-index: 3;
    right: 40px;
    top: 40%;

    &-box {
        backdrop-filter: blur(16px);
        width: 48px;
        height: 146px;
        padding: 0 6px;
        background: #FFFFFF;
        box-shadow: 0px 2px 16px 0px rgba(61, 68, 102, 0.1);
        border-radius: 32px;
        text-align: center;

        &-content {
            padding: 6px 0px;
            text-align: center;
            border-bottom: 1px solid #F0F0F0;

            &-box {
                display: flex;
                align-items: center;
                width: 36px;
                height: 36px;
                border-radius: 18px;

                >img {
                    margin: 0 auto;
                }

                &:hover {
                    background-color: rgba(246, 73, 43, 0.05);
                }
            }

            &_wxcode {
                text-align: center;

                >img {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        &-content:last-child {
            border-bottom: none;
        }
    }
}
</style>
