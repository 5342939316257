<style lang="less" scoped>

.list {
  position: relative;
  display: flex;
  height: 100%;
  &_right {
    flex: 1;
    position: relative;
    .ant-tabs-nav-scroll {
      text-align: left;
    }
    &_content {
      position: relative;
      box-sizing: border-box;
      padding: 58px 58px 0 40px;
      height: calc(100% - 64px);
      overflow-y: auto;
      overflow-x: hidden;
    }
    &_list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 50px;
      padding: 0;
      margin-right: -108px;
      overflow-x: visible;
    }
    .dragg {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &_single {
      list-style: none;
      margin-right: 46px;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      &:hover {
        .drag-icon {
          display: block;
          z-index: 1;
        }
        .undown {
          display: block;
        }
      }
      .mac-disabled {
        background: rgba(0, 0, 0, 0.65);
        border-radius: 4px;
        color: white;
        padding: 4px;
        position: absolute;
        left: 8px;
        top: 8px;
      }
      .drag-icon {
        display: none;
        width: 24px;
        position: absolute;
        top: 168px;
        left: 8px;
      }
      .list_right_cover {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        // border-radius:4px 10px 10px 4px;
        //  box-shadow: 4px 4px 16px #eeeeee;
      }
      .high-light {
        position: absolute;
        top: 0;
      }
      &--add {
        border-radius: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 160px;
        height: 160px;
        border: 1px dashed rgba(0, 0, 0, 0.25);
      }
      .undown {
        &.show{
          display: block;
        }
        display: none;
        border-radius: 10px;
        opacity: 0.7;
        background: rgba(0, 0, 0, 0.85);
        width: 160px;
        height: 160px;
        position: absolute;
        top: 0;
        color: white;
        padding: 76px 35px 0 35px;
        text-align: center;
        .icon-box {
          position: absolute;
          width: 160px;
          height: 160px;
          left: 0;
          top: 0;
          line-height: 160px;
          i {
            font-size: 30px;
            display: inline-block;
            width: 48px;
            height: 48px;
            line-height: 50px;
            border-radius: 24px;
            border: 2px solid #ffffff;
          }
        }
        .word {
          margin-top: 45px;
          font-size: 14px;
          color: #ffffff;
        }
        .ant-progress-text {
          color: white;
        }
        .off {
          border: 1px solid #fff;
          font-size: 18px;
          //font-weight: 600;
          border-radius: 4px;
          display: inline-block;
          padding: 0 5px;
          //transform: rotate(-30deg);
        }
      }
    }
    &_text {
      width: 160px;
      height: 70px;
      overflow: hidden;
      margin-top: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      text-align: left;
      line-height: 24px;
    }
    &_down {
      font-size: 18px;
      color: #2c91e0;
      letter-spacing: 0;
      text-align: center;
      // margin-top: 8px;
    }
    &_addBook {
      font-size: 16px;
      color: #bbbbbb;
      letter-spacing: 0;
      text-align: center;
      margin: 0;
    }
  }
  &_add {
    background: #e5e5e5;
    &_text {
      color: #999;
      margin-top: 10px;
      font-size: 12px;
    }
  }
}
.completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 173px;
    height: 173px;
  }
  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }
  .login-btn {
    width: 224px;
    margin-top: 40px;
  }
  .login-btn {
    height: 48px;
    background: #f73210;
    border-radius: 4px;
    font-size: 20px;
    color: #ffffff;
    border: 0;
    cursor: pointer;
  }
}
.blank {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 72px;
  -webkit-user-select: none;
  button {
    background: white;
    width: 220px;
    height: 48px;
    border: 1px solid #f73210;
    border-radius: 4px;
    font-size: 20px;
    color: #f73210;
    cursor: pointer;
  }
}
.ant-notification {
  z-index: 10000000;
  -webkit-app-region: no-drag;
}
.source-modal {
  .ant-modal {
    -webkit-app-region: no-drag;
    width: 100%;
    height: 100%;
  }
  .ant-modal-centered .ant-modal {
    top: 0;
    vertical-align: top;
    padding: 0;
  }
  .ant-modal-content {
    height: 100%;
    .ant-modal-body {
      padding: 0;
      height: 100%;
    }
  }
}
.ant-popover {
  z-index: 100;
}
.recycler {
  cursor: pointer;
  position: fixed;
  text-align: center;
  bottom: 0;
  font-size: 16px;
  width: calc(100% - 320px);
  left: 321px;
  height: 50px;
  margin: 0;
  padding-top: 10px;
  background-color: white;
}
.rightMenu {
  width: 96px;
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  font-size: 18px;
  position: fixed;
  z-index: 100;
  line-height: 44px;
  text-align: center;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
  :hover {
    color: white;
  }
  div:first-of-type {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.backButton {
  position: fixed;
  top: 20px;
  font-size: 20px;
  -webkit-app-region: no-drag;
  cursor: pointer;
}
.ghost {
  width: 160px;
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.25);
  height: 300px;
  * {
    opacity: 0 !important;
  }
}
.drag {
  opacity: 0.7;
  filter: alpha(opacity=70) !important;
}
.ant-progress {
  width: 90px;
}
.filter{
  font-size: 16px;
  margin-top: -16px;
  margin-bottom: 20px;
  overflow: hidden;
  >.ant-select {
    margin-right: 8px;
  }
}
.stage{
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 8px;
  }
.page{
  text-align: center;
}
</style>
<template>
  <div class="textbook">
    <div class="filter">
      <a-select
          v-model="filterData.stage"
          placeholder="请选择学段"
          style="width:120px;height: 48px"
        >
          <a-select-option key="">全部学段</a-select-option>
          <a-select-option v-for="stage in stageList" :key="stage.value">{{ stage.label }}</a-select-option>
        </a-select>
        <a-select
        v-model="filterData.grade"
          placeholder="请选择年级"
          style="width:120px;height: 48px"
        >
          <a-select-option key="">全部年级</a-select-option>
          <a-select-option v-for="grade in gradeList" :key="grade.gradeCode">{{ grade.gradeName }}</a-select-option>
        </a-select>
        <a-select
        v-model="filterData.subject"
          placeholder="请选择学科"
          style="width:120px;height: 48px"
        >
          <a-select-option key="">全部学科</a-select-option>
          <a-select-option v-for="subject in subjectList" :key="subject.subjectCode">{{ subject.subjectName }}</a-select-option>
        </a-select>
    </div>
    <ul class="list_right_list" v-show="bookList.length">
      <li
        v-for="item in bookList"
        :key="item.bookId"
        class="list_right_single handle"
        @click="bookClick(item)"
      >
        <span class="mac-disabled" v-show="item.macDisabled">仅window可用</span>
        <img :src="item.bookCoverUrl" class="list_right_cover" />
        <img src="@/assets/icon/highLight.png" class="high-light" v-if="false" />
        <div class="undown" v-if="!item.existsFlag">
          <p class="off">加入书架</p>
        </div>
        <div class="undown show" v-show="item.existsFlag">
          <p class="off">已加入</p>
        </div>
        <p class="list_right_text">{{ item.bookName }}</p>
      </li>
    </ul>
    <div class="blank" v-show="!bookList.length">
        <img src="@/assets/icon/img_null.png">
    </div>
    <div class="page">
      <a-pagination v-model="current" :total="total" @change="pageChange"/>
    </div>
  </div>
</template>

<script>
import {getGrade,getSubject} from '@/api/user'
import {addBook,getTextbook} from '@/api/videoResource'
import {mapGetters} from 'vuex'
export default {
  data() {
    return {
      bookList: [],
      offline: !navigator.onLine,
      allGradeList: [],
      visible:false,
      gradeList: [],
      subjectList:[],
      stageList:[{value:1,label:'小学'},{value:2,label:'初中'},{value:3,label:'高中'},],
      filterData:{
        stage:'',
        grade:'',
        subject:'',
      },
      current:1,
      total:0
    };
  },

  watch:{
    filterData:{
      handler(n,o) {
        this.gradeList = n.stage ? this.allGradeList.filter(item=>item.stageCode===n.stage) : this.allGradeList;
        if(!this.gradeList.length || (n.stage && parseInt(this.filterData.grade/10)!==n.stage)){
          this.filterData.grade = '';
        }
        this.getList();
      },
      deep:true
    }
  },
  computed:{
    ...mapGetters({ user: "getUser" }),
  },
  async mounted() {
    await getGrade().then((res) => {
        this.allGradeList = res.data;
        this.gradeList = res.data;
    });
    await getSubject().then((res) => {
        this.subjectList = res.data;
    });
    this.getList()
  },
  methods: {
    getList(page) {
      getTextbook({
          page:page||1,
          size:8,
          userId:this.user.userId,
          stage:this.filterData.stage,
          grade:this.filterData.grade,
          subject:this.filterData.subject,
          type:1
        })
        .then((res) => {
          this.bookList = res.data.bookShelfDTOList;
          this.total = res.data.totalCount
        });
    },
    bookClick(item) {
      if(item.existsFlag) return
      addBook({bookId:item.bookId,userId:this.user.userId,platform: 'PC',type:1}).then(()=>{
        this.$message.success('加入成功');
        item.existsFlag = true
        this.$emit('add')
      })
    },
    pageChange(page,pageSize){
      this.getList(page)
    }
  },
};
</script>

