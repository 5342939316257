<template>
  <div class="help" style="font-family: PingFangSC-Light;" >
        <ul class="questionNav">
            <li v-for="(item,index) in questionNavList " :key="index" class="questionNav_single" :class="{'questionNav_single--select':currentPosition==index}" @click="questionNavClick(item,index)">{{item.name}}</li>
        </ul>
        <div class="hotDetail">
            <ul class="hotDetail_list">
                <li class="hotDetail_list_single" v-for="(item,index) in helpDTOList" :key="index" @click="express(item,index)" >
                    <div class="hotDetail_list_single_icon" >
                        <img class="hotDetail_list_single_icon_img" :src="item.isExpress == true?require('../assets/icon_pull-up.png'):require('../assets/icon_pull-down.png')" alt="" >
                    </div>
                    <span class="hotDetail_list_single_title">{{item.title}}</span>
                    <div class="hotDetail_list_single_childrenContent" v-show="item.isExpress" @click.stop="" v-html="item.helpContent"></div>
                    <!-- <div class="hotDetail_list_single_childrenContent" v-for="(children,index1) in item.helpContent" :key="index1" v-show="isExpress">
                    {{children}}
                    </div> -->
                </li>
            </ul>               
        </div>
  </div>
  
</template>

<script>
export default {
    props:{

    },
    data() {
        return{
            // picture:require('../assets/icon_pull-down.png'),
            // ../assets/icon_pull-down.png
            currentPosition:0,
            questionNavList:[
                {
                    name:'热门问题',
                    path:'/help',
                },
                {
                    name:'激活码问题',
                    path:'/help/activationQuestion'
                },
                {
                    name:'客户端问题',
                    path:''
                },
                {
                    name:'其他问题',
                    path:''
                },
            ],
            //模拟假数据
            isExpress:false,
            helpDTOList:[],
            helpType:1
        }
    },
    methods:{
        //点击事件，控制问题导航样式变化
        questionNavClick(item,index) {
            if(this.currentPosition!==index) {
                this.currentPosition = index
                this.helpType = index + 1
                this.help()
            }
        },
        //从后端获取数据
        help() {
            this.$axios.get('/api/v1/help',{
                params:{
                    page:1,
                    size:100,
                    helpType:this.helpType
                }
            })
            .then(res=>{
                let vm = this
                res.data.helpDTOList.map((e)=>{ 
                    e.isExpress = vm.isExpress
                    //已经有了isExpress参数
                    // console.log(e)
                })

                this.helpDTOList=res.data.helpDTOList;
            })
        },
        //展开内容，并让标题的icon图标变化
        express(item,index){
            item.isExpress = item.isExpress==false?true:false
            // this.picture = item.isExpress == false?require('../assets/icon_pull-down.png'):require('../assets/icon_pull-up.png')
        }
    },
    created() {
        this.help()
    }
}
</script>

<style lang="less">
.help{
    width: 100%;
    height: 100%;
}

.questionNav{
    margin-left: 84px;
    padding-top: 56px;
    display: flex;
    flex-grow: 1;
    &_single{
        width: 80px;
        height: 39px;
        font-size: 14px;
        color: #000000;
        list-style: none;
        margin-right: 24px;
        line-height: 39px;
        text-align: center;
        cursor: pointer;
        &--select{
            color: #F73210;
            border-bottom: 2px solid #F73210;
        }
        &:hover{
            color: #F73210;
            border-bottom: 2px solid #F73210; 
        }
    }
}   
.hotDetail{
  margin-top: 32px;
  margin-left: 84px;
  margin: 32px 160px 0 84px ;
  &_list{

    list-style: none;
    font-size:16px;
    font-family:MicrosoftYaHei;
    color:rgba(0,0,0,0.65);
    &_single{
      display: block;
      cursor: pointer;
      // height: 18px;
      padding-top: 15px;
      padding-bottom: 17px;
      &_title{
          color: rgba(0, 0, 0, 0.65);;
      }
      &_childrenContent{
        margin-top: 10px;
        margin-left: 24px;
        font-size:14px;
        color: rgba(0, 0, 0, 0.45);
      }

      &:nth-of-type(1) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      }
      &:nth-of-type(2) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      }
      &:nth-of-type(3) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      }
      &:nth-of-type(4) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      }
      &:nth-of-type(5) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      }
      &_icon{
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
        // border: 1px dashed ;
        margin-right: 10px;
        &_img{
          margin-bottom: 2px;
        }
      }
    }
  }
} 

</style>