import { render, staticRenderFns } from "./userInfo.vue?vue&type=template&id=89f2829a&scoped=true&"
import script from "./userInfo.vue?vue&type=script&lang=js&"
export * from "./userInfo.vue?vue&type=script&lang=js&"
import style0 from "./userInfo.vue?vue&type=style&index=0&id=89f2829a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89f2829a",
  null
  
)

export default component.exports