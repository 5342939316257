<template>
  <div class="detail" style="font-family: PingFangSC-Light">
    <div class="detail-header">
      <div class="content">
        <img src="../assets/detail/banner_xxyy.png" v-if="type == 1" />
        <img src="../assets/detail/banner_xxsx.png" v-if="type == 2" />
        <img src="../assets/detail/banner_xxyw.png" v-if="type == 3" />
        <img src="../assets/detail/banner_xxjs.png" v-if="type == 4" />
        <img src="../assets/detail/banner_czsx.png" v-if="type == 5" />
        <img src="../assets/detail/banner_czyy.png" v-if="type == 6" />
        <div class="all-tab">
          <img src="../assets/detail/title_xxyy.png" @click="type=1" v-show=" type != 1"/>
          <img src="../assets/detail/title_xxyy_sel.png" v-show=" type == 1"/>

          <img src="../assets/detail/title_xxsx.png" @click="type=2" v-show=" type != 2"/>
          <img src="../assets/detail/title_xxsx_sel.png" v-show=" type == 2"/>

          <img src="../assets/detail/title_xxyw.png" @click="type=3" v-show=" type != 3"/>
          <img src="../assets/detail/title_xxyw_sel.png" v-show=" type == 3"/>

          <img src="../assets/detail/title_xxxxjs.png" @click="type=4" v-show=" type != 4"/>
          <img src="../assets/detail/title_xxxxjs_sel.png" v-show=" type == 4"/>

          <img src="../assets/detail/title_czsx.png" @click="type=5" v-show=" type != 5"/>
          <img src="../assets/detail/title_czsx_sel.png" v-show=" type == 5"/>

          <img src="../assets/detail/title_czyy.png" @click="type=6" v-show=" type != 6"/>
          <img src="../assets/detail/title_czyy_sel.png" v-show=" type == 6"/>
        </div>
      </div>
    </div>
    <div class="detail-bottom">
      <img
        src="../assets/detail/content.jpg"
        style="display: block; margin: 0 auto"
      />
    </div>
    <div class="attentionBox">
      <div v-show="isShow" class="attentionPic">
        <img src="../assets/wecom-temp-441ae10872831861cf94fd4d30e4514a.png" alt="">
        <span></span>
      </div>
      <!-- <div @mouseenter="isShow = true" @mouseleave="isShow = false" class="text">
        <span>
          扫码关注
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      type: "1",
      isShow: false
    };
  },
  methods: {},
  created() {},
};
</script>

<style lang="less">
.detail-header {
  background: url("../assets/detail/bg.jpg") no-repeat;
  background-size: 100% 100%;
  height: 582px;
  padding-top: 102px;
      box-sizing: border-box;
  .content {
    position: relative;
    margin: 0 auto;
    width: 1042px;
    height: 381px;
    .title {
      position: absolute;
      top: 117px;
      left: 910px;
      color: #e98237;
      width: 160px;
      text-align: left;
      font-size: 18px;
      font-weight: 600;
    }
    .banner {
      position: absolute;
      top: 170px;
      right: 140px;
      background: #efc272;
      color: white;
      padding: 5px;
    }
    .message {
      position: absolute;
      width: 400px;
      right: 36px;
      top: 230px;
      line-height: 1.9;
    }
    .all-tab {
      position: absolute;
      bottom: -40px;
      img{
        cursor: pointer;
      }
      .border {
        display: inline-block;
        width: 1px;
        height: 12px;
        background: black;
        margin: 0 3px;
      }
      .tab {
        cursor: pointer;
        display: inline-block;
        padding: 0 48px;
        line-height: 32px;
        &.active {
          background: #e3573a;
          color: white;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>