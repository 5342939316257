<template>
  <!-- 音视频播放组件 -->
  <div class="online-video">
    <video  v-if="isVideo" oncontextmenu="return false" :controlslist="hideDownload?'nodownload':''" id="my-video" autoplay="autoplay" style="width: 100%" controls :src="videoSrc"></video>
    <div style="height:100%" v-if="isPdf">
			<a-spin :spinning="iframeLoading" class="iframeLoading">
			</a-spin>
			<iframe :style="{opacity:iframeLoading?0:1}" @load="onLoad" :src="`${pdfSrc}#toolbar=0`" type="application/pdf" width="100%" :height="clientHeight - 140" />
		</div>
    <div class="audioBox" v-show="isAudio">
      <audio :loop="(this.mode == 1)" ref="audioRef" class="audio" autoplay="autoplay" :src="audioSrc" controls="controls" @timeupdate="timeUpdate" crossorigin=""></audio>
      <div class="audioBox_content">
        <h3>{{ audioName }}</h3>
        <div class="audioBox_content_box">

          <img class="main-action" v-if="mode===0"  @click="mode=1" src="@/assets/video/cycle.svg" alt="" />
          <img class="main-action" v-if="mode===1"  @click="mode=0" src="@/assets/video/singlecycle.svg" alt="" />
          <div class="speed-wrapper" @mouseleave="showSpeed = false">
            <div class="mypopup" v-show="showSpeed" >
              <img @click="speedValue= Math.min(3,speedValue+.1)" src="../assets/video/plus.svg" alt="" />
                <a-slider :min="0.5" :step="0.1" :max="3" v-model="speedValue" vertical :tip-formatter="null" />
              <img @click="speedValue= Math.max(0.5,speedValue-.1)" src="../assets/video/minus.svg" alt="" />
            </div>
              <div @click="showSpeed=true" class="main-action speed" >
                {{speedValue.toFixed(1)}}x
              </div>
          </div>

          <img  v-if="isPause" @click="pauseOrPlay" src="@/assets/video/pause.svg" alt="" />
          <img  v-else @click="pauseOrPlay" src="@/assets/video/play.svg" alt="" />
          <span style="display: inline-block; width: 85px" class="currentTime">{{ currentTime }}</span>
          <a-slider class="mian-slider" :min="0" :max="durationTime" @change="changeProgress" v-model="progross" :defaultValue="value" :tip-formatter="null" />
          <span class="duraTime">{{ totalTime }}</span>
          <div class="volume-wrapper" @mouseleave="showVolume = false">
            <div class="mypopup" v-show="showVolume" >
              <img @click="volumeValue= Math.min(100,volumeValue+20)" src="../assets/video/plus.svg" alt="" />
                <a-slider v-model="volumeValue" vertical :tip-formatter="null" />
              <img @click="volumeValue= Math.max(0,volumeValue-20)" src="../assets/video/minus.svg" alt="" />
            </div>
            <img  class="main-action volume-wrapper-icon" @click="handleVolume" src="../assets/video/volume.svg" alt="" />
          </div>
          <img v-if="!hideDownload" @click="hanldeDownload" src="../assets/video/download.svg"  class="main-action" />
        </div>
      </div>

    </div>
    <div class="no_resource" v-if="noResource">
      <template>
        <a-empty>
          <span slot="description">本小节暂无资源！</span>
        </a-empty>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {getFileTempUrl, download, userConfig} from '@/api/videoResource';

export default {
  name: 'OnlineLearning',
  components: {},
  props: ['list', 'audioName', 'hideDownload'],
  data() {
    return {
      mode:+localStorage.getItem('mode')||0,
      isVideo: false, // 判断数据类型是否为video
      isAudio: false,
      isPdf: false,
      noResource: true,
      iframeLoading: true,
      speedValue:+localStorage.getItem('speed')||1.0,
      videoList: [],
      videoSrc: '',
      audioSrc: '',
      pdfSrc: '',
      isPause: true, // 控制暂停或播放
      value: 0,
      showSpeed: false,
      showVolume: false,
      volumeValue: 50,
      currentTime: '00:00',
      totalTime: '00:00',
      progross: 0, //进度，百分比
      durationTime: 0,
			mustFlag: false
    };
  },
  created() {
  },
  mounted() {
    const video = this.$refs.audioRef;
    if (video != null) {
      video.load();
      video.oncanplay = () => {
        this.totalTime = this.getTime(Math.round(video.duration));
        this.durationTime = Math.round(video.duration);
        video.playbackRate= this.speedValue
      };
      video.onended = ()=>{
        if(this.mode == 0){
          this.$emit('next')
        }
      }
    }
  },
  methods: {
    onLoad(){
      setTimeout(()=>this.iframeLoading = false,1000)//产品 不要渲染时的黑背景
    },
    timeUpdate() {
      this.currentTime = this.getTime(this.$refs.audioRef.currentTime.toFixed(0));
      this.progross = Math.round(this.$refs.audioRef.currentTime);
    },

    getTime(s) {
      let min = (parseInt(s / 60) + '').padStart(2, '0');
      let ss = (Math.floor(s % 60) + '').padStart(2, '0');
      return min + ':' + ss;
    },

    pauseOrPlay() {
      // console.log("暂停或者播放")
      this.isPause = !this.isPause;
      if (!this.isPause) {
        this.$refs.audioRef.pause();
      } else {
        this.$refs.audioRef.play();
      }
    },

    changeProgress(value) {
      // 点击改变当前播放进度
      this.$refs.audioRef.currentTime = value;
    },

    handleVolume() {
      // console.log("显示音量")
      this.showVolume = !this.showVolume;
    },

    // 点击下载音频
    async hanldeDownload() {
      const blob = await download(this.audioSrc);
      const link = document.createElement('a');
      const href = URL.createObjectURL(new Blob([blob.data]));
      link.href = href;
      link.download = this.audioName + '.mp3';
      link.click();
      URL.revokeObjectURL(href);
      // window.location.href =`${this.audioSrc}&response-cache-control=no-cache&esponse-content-type=application/octet-stream`
    },

    volumeChange(val) {
      if(this.$refs.audioRef){
        this.$refs.audioRef.volume = val / 100;
      }
    },
    speedChange(val) {
      localStorage.setItem('speed',val)
      if(this.$refs.audioRef){
        this.$refs.audioRef.playbackRate = val;
      }
    }
  },
  computed: {
    ...mapGetters({ userInfo: 'getUser' }),
    clientHeight(){
      return document.documentElement.clientHeight || window.innerHeight
    }
  },
  watch: {
    volumeValue:'volumeChange',
    speedValue:'speedChange',
    mode(val){
      localStorage.setItem('mode',val||0)
    },
    list: async function (newVal, oldVal) {
      // 监听父组件传递的list的变化，当list改变时再去加载数据
      // async getResourceInfo() {
      this.isPause = true; // 每次切换目录时，重置为播放图标
      if (this.list) {
        // console.log('111', this.list)
        const { data: res } = await getFileTempUrl({
          resourceIdList: [this.list.resourceId]
          // collectionBookId: this.list // 收藏书本id
        });
        // console.log('333', res[0])
        this.videoList = res[0];
        // console.log('222', this.videoList)
        if (this.videoList.resourceType == 2) {
          // mp3
          // console.log('音频')
          this.isAudio = true;
          this.isVideo = false;
          this.isPdf = false;
          this.noResource = false;
          this.audioSrc = this.videoList.fileUrl;
        } else if (this.videoList.resourceType == 1) {
          // mp4 或 视频
          // console.log('视频')
          this.isVideo = true;
          this.isAudio = false;
          this.isPdf = false;
          this.noResource = false;
          this.videoSrc = this.videoList.fileUrl;
        }else if(this.videoList.resourceType == 8){
          this.isPdf = true;
          this.isVideo = false;
          this.isAudio = false;
          this.noResource = false;
          this.pdfSrc = this.videoList.fileUrl;
				  this.iframeLoading = true
        }  else {
          // console.log('错误类型')
          return false;
        }
      } else {
        // console.log('无数据！');
        this.noResource = true;
        this.isVideo = false;
        this.isAudio = false;
      }
      // }
    },
    deep: true
  }
};
</script>

<style lang="less" scoped>
* {
  font-family: PingFangSC-Regular;
}
.online-video {
  width: 100%;
  height: 100%;

  .iframeLoading {
		display: block;
		text-align: center;
		margin: 150px auto;
	}
  .speed-wrapper {
    position: relative;
        &::after {
      position: absolute;
      content: '';
      height: 10px;
      bottom: 100%;
      width: 100%;
      left: 0;
    }
  }
  .mypopup {
      position: absolute;
      bottom: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      height: auto;
      background-color: #fff;
      width: 32px;
      border-radius: 16px;
      padding: 0!important;
      >img {
        cursor: pointer;
        padding: 10px 0;
      }

      /deep/.ant-slider-vertical {
        height: 120px;
      }
      /deep/.ant-slider-track {
        width: 6px;
        background-color: #f6492b !important;
      }
      /deep/.ant-slider-rail {
        background-color: #ccc;
        width: 6px;
      }
      /deep/.ant-slider-handle {
        background-color: #f6492b;
        width: 20px;
        height: 20px;
        margin-left: -8px;
        border: 6px solid #fff;
        box-shadow: 0 0 6px 0px #eee;
      }
      /deep/.ant-slider-handle:hover {
        background-color: #f6492b;
        width: 20px;
        height: 20px;
        margin-left: -8px;
        border: 6px solid #fff;
      }
      .ant-slider:hover /deep/.ant-slider-handle:not(.ant-tooltip-open) {
        border-color: #fff;
      }
    }

  .volume-wrapper {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      height: 10px;
      bottom: 100%;
      width: 100%;
      left: 0;
    }

  }

  .audioBox {
    background: url(../assets/video/bg.jpg) no-repeat center center;
    background-size: 100% 100%;
    position: relative;
    width: 900px;
    height: 507px;

    .audio {
      width: 40%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -9;
    }

    &_content {
      padding-top: 377px;

      h3 {
        padding: 0 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 24px;
        color: #333;
        font-weight: normal;
        margin-bottom: 40px;
        height: 36px;
      }

      &_box {
        display: flex;
        align-items: center;
        padding: 0 95px;
        .main-action {
          margin-right: 20px;
        }
        .speed {
          width: 32px;
          height: 32px;
          background: #fff;
          border-radius: 50%;
          color: #f6492b;
          line-height: 32px;
          font-size: 12px;
          min-width: 32px;
          text-align: center;
          cursor: pointer;
        }
        img {
          cursor: pointer;
          width: 32px !important;
          height: 32px !important;
        }

        span {
          font-size: 16px;
          color: #333;
          padding: 0 20px;
        }
        .mian-slider {
          margin: 10px 6px;
          width: 480px;
          height: 6px;

          /deep/.ant-slider-track {
            height: 6px;
            background-color: #f6492b !important;
          }
          /deep/.ant-slider-step {
            height: 6px;
          }
          /deep/.ant-slider-handle {
            background-color: #f6492b;
            width: 20px;
            height: 20px;
            margin-top: -7px;
            border: 6px solid #fff;
          }
          /deep/.ant-slider-handle:hover {
            background-color: #f6492b;
            width: 20px;
            height: 20px;
            margin-top: -7px;
            border: 6px solid #fff;
          }
          /deep/.ant-slider-handle:focus {
            box-shadow: 0 0 0 0;
          }
          /deep/.ant-slider-rail {
            background-color: #ccc;
            height: 6px;
          }
        }
   

      }
    }

  }

  .no_resource {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
  }
}
</style>