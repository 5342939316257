<template>
	<div class="preview-root">
		<iframe v-if="url" :src="url" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"/>
	</div>
</template>

<script>
import {buryingPoint, getPoints} from "@/api/prize";

export default {
	name: 'Preview',
	components: {},
	props: {},
	data() {
		return {
			url: '',
			bookId: '',
			timer: {},
			num: 0
		}
	},
	async beforeDestroy() {
		clearInterval(this.timer)
		await this.sendBuryingPoint()
	},
	mounted() {
		this.url = decodeURIComponent(this["$route"].query.url)
		this.bookId = this.$route.query.bookId;
		this.startTiming()
	},
	methods: {
		async sendBuryingPoint(){
      const userId = JSON.parse(localStorage.user)?.userId
      if(!userId || !this.bookId)return
			const currentDuration = await getPoints({
				userId,
				bookId: this.bookId,
				taskCode: 'EFFECTIVE_LEARNING'
			})
			const num = Number((this.num / 60).toFixed(2))+currentDuration.data
			await buryingPoint({
				activityCode:  localStorage.getItem('activityCode')||'E_STUDY',
				actionCode: 'PREVIEW_MATERIAL',
				userId,
				otherParams: `bookId#${this.bookId}@time#${num}`
			});
			this.num = 0
		},
		startTiming() {
			const that = this
			this.timer = setInterval(() => {
				++that.num
				if(that.num>=120){
					that.sendBuryingPoint()
				}
				// console.log(this.num)
			}, 1000)
		},
	}
}
</script>

<style lang="less" scoped>
.preview-root {
	display: inline-block;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: #fff;
	z-index: 21474836426;
  padding-top:0 !important;
}

iframe {
	width: 100vw;
	height: 100vh;
}
</style>
