import axios from "@/api/request";
import base from './base';
export const getFeedBackList = (params)=>{
    return axios.get(`${base}/api/v1/feedback/web`,{params})
}
export const getFeedBackType = (params)=>{
    return axios.get(`${base}/api/v1/feedback/type`,{params})
}
export const addFeedBack = (params)=>{
    return axios.post(`${base}/api/v1/feedback`,params)
}
export const sendCode = (params)=>{
    return axios.get(`${base}/api/v1/pub/user/mobile/code`, {params})
}
export const userCancel = (params)=>{
    return axios.post(`${base}/api/v1/pub/client/cancel`, params)
}
