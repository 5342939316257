<template>
  <div class="ChangePhone">
    <div class="title">更换注册手机号</div>
    <div class="subtitle">
      {{ tip }}
    </div>
    <a-form-model
      :rules="rules"
      style="width: 100%"
      ref="ruleForm"
      :model="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <template v-if="step === 0">
        <a-form-model-item prop="ophone">
          <a-input
            @blur="onPhoneChange(form.ophone)"
            placeholder="请输入原注册手机号"
            v-model="form.ophone"
          >
            <img src="../assets/icon/phone.png" class="icon" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input
            type="password"
            placeholder="请输入登录密码"
            v-model="form.password"
          >
            <img src="../assets/icon/password.png" class="icon" slot="prefix" />
          </a-input>
        </a-form-model-item>
      </template>
      <template v-if="step === 1">
        <a-form-model-item prop="phone">
          <a-input
            @blur="onPhoneChange(form.phone)"
            placeholder="请输入新手机号"
            v-model="form.phone"
          >
            <img src="../assets/icon/phone.png" class="icon" slot="prefix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="smsCode">
          <a-input
            class="smsInput"
            style="width: 232px"
            placeholder="请输入验证码"
            v-model="form.smsCode"
          >
            <img src="../assets/icon/captcha.png" class="icon" slot="prefix" />
          </a-input>
          <div
            class="smsBtn"
            :class="{'smsBtn--disabled':!!this.time}"
            @click="sendSmsCode()"
            >{{ time ? `${time}秒后重新发送` : "获取验证码" }}</div
          >
        </a-form-model-item>
      </template>
      <div v-if="error" class="error">{{ error }}</div>
    </a-form-model>
    <div style="text-align: right;margin-top: 8px;">
      <a-button @click="$emit('cancel')">取消</a-button>
      <a-button
        class="btn"
        :class="{ 'btn--disabled': btnDisabled }"
        @click="next()"
        type="primary"
        >{{ nextText }}</a-button
      >
    </div>
  </div>
</template>

<script>
import { verifyPassword, getMobileCodeV2, updateMobile } from "@/api/user";
import Cookies from "js-cookie";
import { mapGetters, mapMutations } from "vuex";
import md5 from "blueimp-md5";
export default {
  data() {
    return {
      time: 0,
      step: 0,
      error: "",
      form: { ophone: "", password: "", phone: "", smsCode: "" },
      rules: {
        feedbackType: [{ required: true, message: "请选择类型" }],
        feedbackDescription: [{ required: true, message: "请输入反馈内容" }],
      },
    };
  },

  components: {},

  props: {},

  methods: {
    ...mapMutations(["setUser"]),
    onPhoneChange(phone) {
      const ret = /^1[3456789]\d{9}$/.test(phone);
      this.error = ret ? "" : "请输入正确手机号";
      return ret;
    },
    async next() {
      if (this.btnDisabled) return;
      if (this.user.phone !== this.form.ophone) {
        return (this.error = "请输入正确手机号");
      }
      if (!this.form.password) {
        return (this.error = "请输入密码");
      }
      if (this.step < 1) {
        if (this.form.password && this.form.ophone) {
          verifyPassword(this.user.userId,{
            password: md5(this.form.password).toUpperCase(),
          })
            .then(() => {
              this.step++;
            })
            .catch((e) => {
              return (this.error = "手机号或密码错误" || e.data.msg);
            });
        }
      } else {
        this.submit();
      }
    },
    submit() {
      if (!this.form.smsCode) {
        return (this.error = "请输入验证码");
      }
      updateMobile(this.user.userId, {
        newMobile: this.form.phone,
        oldMobile: this.form.ophone,
        token: this.form.smsCode,
      })
        .then((res) => {
          this.$emit("cancel");
          this.$message.success("换绑成功");
          localStorage.removeItem("user");
          this.setUser({});
          Cookies.remove("commonName", { domain: "zjeav.com" });
          this.$router.push("/");
        })
        .catch((err) => {
          this.error = err.data.msg;
        });
    },
    sendSmsCode() {
      if (this.time) return;
      if (!this.onPhoneChange(this.form.phone)) {
        return;
      }
      getMobileCodeV2({
        mobile: this.form.phone,
        platform: "WEB",
        usage: 3,
        explosionProof: false,
      })
        .then(() => {
          this.time = 60;
          let a = setInterval(() => {
            this.time = this.time - 1;
            if (!this.time) clearInterval(a);
          }, 1000);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {},
  computed: {
    ...mapGetters({ user: "getUser" }),
    tip() {
      return [
        "请输入原账号密码进行身份验证。",
        "更换后，可使用新手机号登录当前账号。",
      ][this.step];
    },
    nextText() {
      return ["下一步", "提交"][this.step];
    },
    btnDisabled() {
      const flag =
        this.step === 0
          ? this.form.ophone && this.form.password
          : this.form.phone && this.form.smsCode;
      return !flag;
    },
  },
};
</script>

<style lang="less" scoped>
.ChangePhone {
  .error {
    color: #ed4014;
    margin-top: -20px;
  }
  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1.15;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 24px;


  }
  .icon {
    width: 16px;
    height: 16px;
    margin-top: 1px;
  }
  // /deep/.ant-form-item-label {
  //   height: 32px !important;
  //   line-height: 32px !important;
  // }
  /deep/.ant-form-item-control-wrapper input {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 500;
  }
  .btn {
    margin-left: 16px;
    background-color: #f6492b;
    border: none;
    &--disabled {
      background: rgba(246, 73, 43, 0.3);
    }
  }
  .smsInput {
    /deep/input {
      width: 100%;
    }
  }
  .smsBtn {
    vertical-align: top;
    cursor: pointer;
    width: 112px;
    line-height: 48px;
    margin-left: 16px;
    text-align: center;
    color: #fff;
    background: #f6492b;
    border-radius: 4px;
    display: inline-block;

    &--disabled {
      background: rgba(0,0,0,0.06);
      color: rgba(0,0,0,0.65);
    }
  }
}
</style>
