<template>
  <div class="prize">
    <div class="prize-time">距离活动开始还剩 {{date}} 天 {{hour}} 时 {{minute}} 分</div>
    <img src="../assets/prize/banner1.png" />
    <img src="../assets/prize/banner2.png" />
    <img src="../assets/prize/banner3.png" />
    <img src="../assets/prize/banner4.png" />
    <img src="../assets/prize/banner5.png" />
    <img src="../assets/prize/banner6.png"/>
		<!-- <div class="link-click" @click="a"></div> -->
<!--    <div class="bottom">-->
<!--      登录即可领取免费奖品，快来下载吧！-->
<!--      <div class="download-button" @click="download">-->
<!--        <img src="../assets/button_banner_2.png" alt class="download_icon" />-->
<!--        <span class="word">客户端下载</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import moment from "moment"
export default {
  props: {},
  data() {
    return {
      date: 0,
      hour: 0,
      minute: 0
    }
  },
  mounted() {
    this.getTime()
    setInterval(this.getTime, 60000)
  },
  methods: {
		a(){
			window.open('https://t.qujiao.com/hKcYAv')
		},
    getTime() {
      let beginTime = moment("2024-02-26 00:00:00")
      let allM = beginTime.diff(new Date(), "minute")
      if (allM > 0) {
        this.date = parseInt(allM / 1440)
        this.hour = parseInt((allM - this.date * 1440) / 60)
        this.minute = parseInt(allM - this.date * 1440 - this.hour * 60)
      }
    },
    download() {
      this.agent = navigator.userAgent.toLowerCase()
      if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.win32Url
        })
      } else if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.winUrl
        })
      } else if (this.isMac) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          console.log(res.data)
          window.location.href = res.data.winUrl
        })
      } else {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.winUrl
        })
      }
    }
  },
  created() {}
}
</script>

<style lang="less">
.prize {
	user-select: none;
  background: #80cfda;
  position: relative;
  img {
    width: 100%;
  }
  .prize-time {
    font-family: SourceHanSansCN-Medium;
    font-size: 24px;
    color: #754503;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 21.8vw
  }
  .bottom {
    font-family: PingFangSC-Regular;
    background: rgba(0, 0, 0, 0.6);
    padding: 0 70px;
    line-height: 80px;
    font-size: 24px;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .download-button {
      display: block;
      width: 237px;
      height: 54px;
      margin: 0 auto;
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      .word {
        position: absolute;
        left: 100px;
        font-size: 18px;
        line-height: 54px;
      }
      img {
        width: 100%;
        vertical-align: unset;
      }
      &:hover {
        box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}
.link-click{
	width: 200px;
	height: 50px;
	left: 46%;
	bottom:9%;
	//background: red;
	cursor: pointer;
	position: absolute;
}
</style>
