import base from './base';
import axios from './request';

// 查询资源列表
export const getResourceList = (params)=>{
    return axios.get(`${base}/api/v1/downloadResource/search/web`, {params})
}

/**
 * 所有出版社
 */
export function getPress() {
	return axios.get(`${base}/api/v1/dictionary/download/press`);
}

/**
 * 所有学科
 */
export function getSubject() {
	return axios.get(`${base}/api/v1/dictionary/subject`);
}

/**
 * 根据学段获取学科
 */
export function getSubjectByStage(stageCode) {
  return axios.get(`${base}/api/v1/dictionary/stage/${stageCode}/subjects`);
}

/**
 * 所有年级
 */
export function getGrade() {
	return axios.get(`${base}/api/v1/dictionary/grade`);
}

// 获取资源源url地址-web
export const getResourceUrl = (resourceId, params)=>{
    return axios.get(`${base}/api/v1/downloadResource/${resourceId}/url`, {params})
}

// 资源统计上报-web
export const getResourceStatistic = (resourceId, params)=>{
    return axios.get(`${base}/api/v1/downloadResource/${resourceId}/statistic`, {params})
}

// 查询目录树
export const getCatalogList = (params)=>{
    return axios.get(`${base}/api/v1/catalog/list`, {params})
}

// 查询目录资源列表
export const catalogResource = (params) => {
    return axios.post(`${base}/api/v1/catalogResource/list`, params)
}

// 根据资源id集合查询资源详情
export const getFileTempUrl = (params) => {
	return axios.post(`${base}/api/v1/catalogResource/details`, params);
}

// 音频下载
export function download (url) {
	return axios.get(url,{responseType: "blob"})
}
