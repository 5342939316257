import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.css';
import store from "./store/index";
// import Grids from 'ant-design-vue/lib/grid';
import axios from "axios"
import 'flex.css'
import "./icons";
axios.defaults.baseURL = "https://api.zjeav.com/"

Vue.prototype.$axios = axios
Vue.use(Antd);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')