<template>
  <div class="feedback">
    <div class="btn" @click="addFeedBack">提交反馈</div>
    <a-table class="table" rowKey="id" :loading="tableLoading" :scroll="{y:380}" bordered :columns="columns" :data-source="data">
      <span slot="status" slot-scope="text, record" :style="{color: text?'#52CC7A':'rgba(0, 0, 0, 0.65)'}">{{text?'已处理':'未处理'}}</span>
      <span slot="type" slot-scope="text, record">{{types[text]}}</span>
      <span slot="action" slot-scope="text, record">
     <a style="color: #F6492B" @click="showDetail(record)">查看</a>
    </span>
    </a-table>
    <DetailModal ref="addModal" @getTypes="getTypes"></DetailModal>
  </div>
</template>
<script>
import DetailModal from './feedbackModal.vue'
import {getFeedBackList} from "@/api/profile";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  components: {
    DetailModal
  },
  data() {
    return {
      columns: [
        {
          title: '编号',
          dataIndex: 'index'
        },
        {
          title: '反馈类型',
          dataIndex: 'feedbackType',
          scopedSlots: {customRender: 'type'},
        },
        {
          title: '反馈内容',
          dataIndex: 'feedbackDescription',width:350
        },
        {
          title: '反馈时间',
          dataIndex: 'createTime',
          customRender: (text) => {
            return moment(text).format('YYYY-MM-DD HH:mm')
          }
        },
        {
          title: '反馈状态',
          dataIndex: 'feedbackStatus',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'action'},
        },
      ],
      data: [],
      tableLoading: false,
      pagination:  {
        total: 0,
        current: 1,
        pageSize: 5,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        showQuickJumper: true,
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ['10', '50', '200'] // 每页数量选项
      },
      types: {}
    }
  },
  created(){
    this.getData()
  },
  methods: {
    async getData(pagination = {}, load = true){
      pagination = { ...this.pagination, ...pagination }
      // this.tableLoading = load
      const res = await getFeedBackList({
        userId: this.user.userId
      })
      this.data = res.data
      this.pagination = { ...pagination, total: +res.data.length }
    },
    async addFeedBack(){
      await this.$refs.addModal.show()
      this.getData({current:1})
    },
     showDetail(record){
       this.$refs.addModal.show(record)
    },
    getTypes(e){
      this.types = Object.assign({}, ...e.map(i => {
        return {
          [i.feedbackType]: i.name
        }
      }))
    }
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
  }
}
</script>

<style scoped lang="less">
.feedback {
  padding-top: 1px;

  .btn {
    background: #F73210;
    border-radius: 4px;
    width: 160px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    font-weight: 400;
    float: right;
    margin-right: 32px;
    margin-top: 24px;
  }

  .table {
    margin: 86px 24px 0;
  }
}
</style>