<template>
  <a-tooltip placement="bottom" v-if='title&&titleType=="tooltip"'>
    <template slot="title">
      <span>{{title}}</span>
    </template>
    <svg :class="svgClassName" aria-hidden="true">
      <use :xlink:href="iconName" />
    </svg>
  </a-tooltip>
  <i v-else-if="title&&titleType=='text'">
    <svg :class="svgClassName" aria-hidden="true">
      <use :xlink:href="iconName" />
    </svg>
    <span class="title">{{title}}</span>
  </i>
  <svg v-else :class="svgClassName" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: "IconSvg",
  props: {
    icon: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    titleType: {
      type: String,
      default: "tooltip" //text：显示在右侧
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.icon}`
    },
    svgClassName() {
      if (this.className) {
        return "svg-icon " + this.className
      } else {
        return "svg-icon"
      }
    }
  }
}
</script>
<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 14px;
  cursor: pointer;
}
</style>
