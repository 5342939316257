var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BeforeLogin',[_c('div',{staticClass:"foget"},[_c('p',{staticClass:"title"},[_vm._v("忘记密码")]),_c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"inputPhone"},[_c('a-form-item',{attrs:{"label":'手机号'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['mobile', { trigger: 'blur',
            rules: [{ required: true, message: '请输入手机号!'},{ message: '请输入正确手机号!',pattern:/^1[3456789]\d{9}$/ }] }]),expression:"['mobile', { trigger: 'blur',\n            rules: [{ required: true, message: '请输入手机号!'},{ message: '请输入正确手机号!',pattern:/^1[3456789]\\d{9}$/ }] }]"}],attrs:{"placeholder":"请输入手机号"},on:{"change":function($event){return _vm.$refs.getSmsbutton.changeVisible(false)}}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../assets/icon/phone.png")},slot:"prefix"})]),_c('GetSmsbutton',{ref:"getSmsbutton",attrs:{"validateFields":_vm.form.validateFields,"usage":"2"}})],1)],1),_c('a-form-item',{attrs:{"label":'验证码'}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['token', { trigger: 'blur',
          rules: [{ required: true, message: '请输入验证码!'},{ message: '请输入验证码!',pattern:/\d{4}$/ }] }]),expression:"['token', { trigger: 'blur',\n          rules: [{ required: true, message: '请输入验证码!'},{ message: '请输入验证码!',pattern:/\\d{4}$/ }] }]"}],attrs:{"placeholder":"请输入手机验证码"}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../assets/icon/captcha.png")},slot:"prefix"})])],1),_c('a-form-item',{attrs:{"label":'密码'}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['newPassword', {
          rules: [{ required: true, message: '请输入登录密码!'},{message: '请设置6-18位登录密码!',min:6,max:18 },{
                    validator: _vm.validateToNextPassword,
                }] }]),expression:"['newPassword', {\n          rules: [{ required: true, message: '请输入登录密码!'},{message: '请设置6-18位登录密码!',min:6,max:18 },{\n                    validator: validateToNextPassword,\n                }] }]"}],attrs:{"placeholder":"设置6-18位登录密码","type":"password"}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../assets/icon/password.png")},slot:"prefix"})])],1),_c('a-form-item',{attrs:{"label":'确认密码'}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['confirmPassword', { rules: [{ required: true, message: '请再次输入密码!' },,{
                    validator: _vm.compareToFirstPassword,
                }] }]),expression:"['confirmPassword', { rules: [{ required: true, message: '请再次输入密码!' },,{\n                    validator: compareToFirstPassword,\n                }] }]"}],attrs:{"placeholder":"请再次输入密码","type":"password"},on:{"blur":_vm.handleConfirmBlur}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../assets/icon/password.png")},slot:"prefix"})])],1)],1),_c('button',{staticClass:"default-btn",on:{"click":_vm.submit}},[_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"loading"}}),_vm._v("提交")],1),_c('p',{staticClass:"bottom"},[_vm._v(" 新用户请先注册 "),_c('span',{staticClass:"black",on:{"click":function($event){return _vm.$router.push('/loginRegistration?loginStep=0')}}},[_vm._v("立即注册 >")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }