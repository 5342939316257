<template>
  <div class="content">
    <div class="title">
      <span>基本信息</span>
      <span class="logout" @click="logOff">
        <img src="../icons/svg/ic_logout.svg" alt=""><span>注销账号</span></span>
    </div>
    <div class="subtitle">
      注册手机号：{{ phoneNuberConvert(user.mobile) }}<a-button style=" color: #F73210;;" type="link" @click="changePhoneVisible=true">换绑</a-button>
    </div>
    <complete :showTitle="true" @userChange="user=$event"/>

    <a-modal
        title=""
        :visible="codeVisible"
        @cancel="codeVisible=false"
        :footer="null"
    >
      <div class="phone">发送短信至{{ user.mobile }}</div>
      <div class="btns">
        <img :src="code" class="code" @click="getuserImg" alt="">
        <div>
          <a-input v-model="codeText" style="margin-bottom: 24px;" placeholder="请输入图形验证码"></a-input>
          <div class="btn" :class="{disabled: !!time}" @click="sendMsg">{{ time ? time : '获取验证码' }}</div>
        </div>
      </div>
      <div class="codes">
        <a-input-number :min="0" :max="9" :precision="0" ref="code" :value="item" @change="nextFocus(index,$event)"
                        v-for="(item,index) of codes" :key="index"/>
      </div>

    </a-modal>
    <a-modal
        title="提醒"
        :visible="tipVisible"
        @cancel="tipVisible=false"
        :footer="null"
    >
      <div class="tip-text">
        账号注销后将不再保留账号内的资源数据，请确认是否注销此账号：<span>{{ user.mobile }}</span>
      </div>
      <div class="btns">
        <div class="btn cancel" @click="tipVisible=false">我在想想</div>
        <div class="btn" @click="confirmLogoff()">注销账号</div>
      </div>
    </a-modal>

    <a-modal
        :visible="changePhoneVisible"
        @cancel="changePhoneVisible=false"
        :footer="null"
        :width="408"
        :closable="false"
    >
    <ChangePhone @cancel="changePhoneVisible=false" v-if="changePhoneVisible"></ChangePhone>
    </a-modal>
  </div>
</template>
<script>
import complete from '../components/Complete.vue'
import {mapGetters, mapMutations} from "vuex";
import {sendCode, userCancel} from "@/api/profile";
import {getuserImgNoPhone} from "@/api/user";
import {phoneNuberConvert} from '@/util'
import ChangePhone from './ChangePhone.vue'
export default {
  components: {complete,ChangePhone},
  data() {
    return {
      changePhoneVisible: false,
      codeVisible: false,
      tipVisible: false,
      codes: [],
      time: 0,
      user: {},
      code: null,
      codeText: ''
    }
  },
  created() {
    this.codes = new Array(4).fill(undefined)
    this.getuserImg()
  },
  methods: {
    ...mapMutations(['setUser']),
    phoneNuberConvert,
    async getuserImg(){
      const res = await getuserImgNoPhone({
        usage: 1,
        // mobile: this.mobile,
        t: new Date().getTime()
      })
      this.code = res
    },
    logOff() {
      this.codes = new Array(4).fill(undefined)
      this.codeVisible = true
    },
    async confirmLogoff(){
      await userCancel({
             "userId":this.user.userId,
             "mobile":this.user.mobile,
             "smsCode":this.codes.join('')
           }
       )
      this.$message.warning('注销成功')
      localStorage.removeItem('user');
      localStorage.removeItem( 'lastUser');
      localStorage.removeItem( 'userName');
      localStorage.removeItem( 'password');
      this.user = { userId:'',userName: '',password:'' };
      this.setUser({ userId:'',userName: '',password:''});

      this.codeVisible = false
      this.tipVisible = false
      this.$router.push('/')
    },
    async nextFocus(index, e) {
      if (e === null) return
      this.$set(this.codes, index, e)
      await this.$nextTick()
      setTimeout(() => {
        this.$refs.code[index + (e === '' ? -1 : 1)]?.focus()
        if (index === 3 && this.codes.every(i => i||i===0)) {
          this.tipVisible = true
        }
      })

    },
    async sendMsg() {
      if (this.time)
        return
      if(!this.codeText){
        return  void this.$message.warning('请输入图形验证码')
      }
      try {
        await sendCode({mobile: this.user.mobile,usage: 1,captchaCode:this.codeText})
        this.time = 60
        this.$refs.code[0].focus()
        let i = setInterval(() => {
          this.time--
          if (this.time === 0)
            clearInterval(i)
        }, 1000)
      }catch (e) {
        this.getuserImg()
      }


    }
  }
}
</script>
<style scoped lang="less">
.code{
  margin: 24px 24px 0 12px;
  width: 240px;
}
.tip-text {
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  font-weight: 400;

  span {
    color: #f73210;
  }

  margin-bottom: 32px;
}

.btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #f73210;
  border-radius: 4px;
  background: #f73210;
  color: #fff;
  cursor: pointer;
  width: 176px;
  margin-right: 32px;
}

.btn.cancel {
  background-color: #fff;
  color: #f73210;
}

.btn.disabled {
  background-color: grey;
  border-color: grey;
}

.codes {
  display: flex;
  padding: 32px;
  justify-content: space-between;

  /deep/ .ant-input-number-handler-wrap {
    display: none;
  }

  /deep/ .ant-input-number {
    width: 66px;
    height: 80px;

    input {
      width: 66px;
      height: 80px;
      font-size: 36px;
      line-height: 36px;
      text-align: center;
      font-weight: 400;
      color: #333333;
    }
  }
}

.phone {
  font-size: 24px;
  color: #333333;
  line-height: 24px;
  font-weight: 600;
}

.content {
  background: #fff;
  min-height: 508px;
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;

  .title {
    box-sizing: border-box;
    width: 100%;
    padding: 32px 64px 0;
    font-size: 20px;
    color: #333333;
    line-height: 30px;
    font-weight: 500;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .logout {
      cursor: pointer;
      font-size: 18px;
      color: #F6492B;
      line-height: 28px;
      font-weight: 400;

      & > * {
        vertical-align: middle;
      }

      img {
        margin-right: 8px;
        height: 18px;
      }
    }
  }
  .subtitle {
    padding: 16px 64px 24px;
    font-size: 14px;
  }
  .user {
    width: 100px;
    height: 100px;
  }

  .Complete {
    // margin-top: 20px;
    width: 440px;
    align-self: start;
    margin-left: 64px;
  }

}

/deep/ .cancel-btn {
  font-size: 16px;
  height: 48px;
  border: 1px solid #f73210;
  border-radius: 3px;
  background: white;
  color: #f73210;
  cursor: pointer;
  width: 148px;
  margin-top: 8px;
  margin-left: 88px;
}

/deep/ .default-btn {
  width: 360px !important;
  font-size: 16px;
  margin-left: 80px;
  vertical-align: bottom;
}

/deep/ .ant-form-item-label {
  width: 80px;
  line-height: 48px;
  height: 48px;
  vertical-align: top;
}

/deep/ .ant-form-item-control-wrapper {
  display: inline-block;

  .ant-form-item-control {
    width: 360px;
  }

  input {
    height: 48px;
    width: 360px;
  }
}
</style>