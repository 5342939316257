<template>
  <div class="download">
    <div class="container">
      <div class="mt-16 mb-12">
        <a-input-search placeholder="输入关键字搜索教材" class="i-search" allowClear v-model="formData.resourceName" @search="getDataList" />
        <a-button type="link" class="rest-form-text" @click="resetFormData"> 重置筛选条件 </a-button>
      </div>
      <div class="s-group">
        <label class="label">学段: </label>
        <span v-for="item in stageList" @click="toggleFilter(item.stageCode, 'stageCode')" :key="item.stageCode" class="form-tag" :class="{ active: formData.stageCode == item.stageCode }">{{ item.stageName }}</span>
      </div>
      <div class="s-group" v-if="formData.stageCode">
        <label class="label">年级: </label>
        <span v-for="item in gradeList" @click="toggleFilter(item.id, 'grade')" :key="item.id" class="form-tag" :class="{ active: formData.grade == item.id }">{{ item.name }}</span>
      </div>
      <template v-if="formData.stageCode != -1">
        <div class="s-group" v-if="formData.stageCode">
          <label class="label">学科: </label>
          <span v-for="item in subjectList" @click="toggleFilter(item.id, 'subject')" :key="item.id" class="form-tag" :class="{ active: formData.subject == item.id }">{{ item.name }}</span>
        </div>
      </template>
      <div class="s-group pb-8 border-bottom mb-40">
        <label class="label press">出版社: </label>
        <span v-for="item in pressList" @click="toggleFilter(item.id, 'pressCode')" :key="item.id" class="form-tag pressTag" :class="{ active: formData.pressCode == item.id }">
          <img style="width: 32px; height: 32px" v-if="item.name !== '全部'" :src="item.iconUrl" alt="" />
          {{ item.name }}</span
        >
      </div>
      <template v-if="dataList.length">
        <div class="content">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <div class="item_left">
              <div class="item_left_tit">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{ item.resourceName }}</span>
                  </template>
                  <span>
                    {{  item.resourceName }}
                  </span>
                </a-tooltip>
              </div>
              <div class="item_left_cont">{{  }}
                {{ item.gradeName }}<template v-if="item.subjectName"><span v-if="formData.stageCode != -1">|</span>{{ item.subjectName }}</template>
              </div>
              <div class="item_left_count">
                <span @click="handleBrowse(item)" v-if="item.resourceType != 4 && item.resourceType != 9">浏览</span>
                <span @click="handleDownload(item)" v-if="item.resourceType!=10&&item.download&&item.resourceId!=='64c71e8a551b3c1c3a45ce67'">下载</span>
<!--                <a-popover title="扫描二维码下载资源" trigger="click" >-->
<!--                  <template slot="content">-->
<!--                    <img :src="item.img" style="width:140px;" alt="">-->
<!--                  </template>-->
<!--                  <span @click="showQrcode(item)">二维码</span>-->
<!--                </a-popover>-->
              </div>
            </div>
            <div class="resourceType">
              <img v-if="item.resourceType == 1 || item.resourceType == 2 || item.resourceType == 5" src="@/assets/downloadSection/yinshipin.png" alt="" />
              <img v-else-if="item.resourceType == 9" src="@/assets/downloadSection/ziyuanbao.png" alt="" />
              <img v-else-if="item.resourceType == 4" src="@/assets/downloadSection/anzhuangbao.png" alt="" />
              <img v-else-if="item.resourceType == 3" src="@/assets/downloadSection/h5.png" alt="" />
              <span v-else></span>
            </div>
            <div class="item_right">
              <img :src="item.resourceCoverUrl" alt="" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="empty">暂无内容</p>
      </template>
      <a-pagination
        v-if="dataList.length"
        class="q-pagination"
        show-size-changer
        show-quick-jumper
        :show-total="total => `共 ${total} 条`"
        :current="formData.page"
        :total="formData.total"
        :pageSizeOptions="['15', '30', '45', '60']"
        :pageSize="formData.size"
        @change="onShowSizeChange"
        @showSizeChange="onShowSizeChange"
      >
      </a-pagination>
    </div>
    <Footer />
    <div class="attentionBox">
      <div v-show="isShow" class="attentionPic">
        <img src="../assets/wecom-temp-441ae10872831861cf94fd4d30e4514a.png" alt="" />
        <span></span>
      </div>
      <!-- <div @mouseenter="isShow = true" @mouseleave="isShow = false" class="text">
        <span> 扫码关注 </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import {
  getGrade,
  getPress,
  getResourceList,
  getResourceStatistic,
  getResourceUrl,
  getSubject,
  getSubjectByStage
} from '@/api/download';
import QRCode from 'qrcode'
import {mapActions} from "vuex";
import {open} from '../util/index'

export default {
  name: 'DownloadSection',
  components: {
    Footer
  },
  props: {},
  data() {
    return {
      formData: {
        resourceName: '', // 资源名称
        pressCode: '', // 出版社code
        subject: '', // 学科
        grade: '', // 年级
        page: 1,
        size: 15,
        total: 0,
        stageCode: 0
      },
      pressList: [], // 出版社
      subjectList: [], // 学科
      gradeList: [], // 年级
      dataList: [], //
      userId: null,
      isShow: false,
      stageList: [
        {
          stageCode: 0,
          stageName: '全部'
        },
        // 学段
        {
          stageCode: -1,
          stageName: '学前'
        },
        {
          stageCode: 1,
          stageName: '小学'
        },
        {
          stageCode: 2,
          stageName: '初中'
        },
        {
          stageCode: 3,
          stageName: '高中'
        }
      ],
      preschoolList: [
        {
          id: -11,
          name: '小班',
          stageCode: -1
        },
        {
          id: -12,
          name: '中班',
          stageCode: -1
        },
        {
          id: -13,
          name: '大班',
          stageCode: -1
        }
      ]
    };
  },
  async created() {
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).userId;
    }
    await this.getFilterList();
    await this.getSubjectList(0);
    await this.getGradeList(0);
    this.getDataList();
  },
  methods: {
    ...mapActions(['toClassServer']),
    // 获取出版社
    async getFilterList() {
      const { data } = await getPress();
      // 需要隐藏的出版社  外语教学与研究出版社 中国地图出版社
      const pressCodeArr = [ 10, 8, 11, 12, 7];
      // const pressCodeArr = [];
      const newPress = data.filter(e => {
        return !pressCodeArr.includes(e.pressCode);
      });
      this.pressList = newPress.map(item => {
        return { id: item.pressCode, name: item.pressName, iconUrl: item.iconUrl };
      });
      this.pressList.unshift({
        id: '',
        name: '全部',
        url: ''
      });
    },

    // 获取年级
    async getGradeList(val) {
      const { data } = await getGrade();
      const arr = data.map(item => {
        return { id: item.gradeCode, name: item.gradeName, stageCode: item.stageCode };
      });
      if (val == 0) {
        this.gradeList.push(...arr)
      } else {
        arr.map(item => {
          if (item.stageCode == val) this.gradeList.push(item);
          return;
        });
      }
      this.gradeList.unshift({
        id: '',
        name: '全部'
      });
    },

    // 获取学科
    async getSubjectList(val) {
      let data
      if (val == 0) {
        const res = await getSubject();
        data = res.data
      } else {
        const res = await getSubjectByStage(val);
        data = res.data
      }
      this.subjectList = data.map(item => {
        return { id: item.subjectCode, name: item.subjectName };
      });
      this.subjectList.unshift({
        id: '',
        name: '全部'
      });
    },

    // 获取列表数据
    async getDataList() {
      const { data } = await getResourceList({
        userId: this.userId,
        ...this.formData
      });
      this.formData.total = data.totalCount;
      this.dataList = data.resourceDTOList.map(e => {
        e.img = ''
        e.gradeName =
          this.gradeList.find(k => {
            return k.id == e.gradeCode;
          })?.name ||
          this.preschoolList.find(j => {
            return j.id == e.gradeCode;
          })?.name;
        let subjectName = [];
        e.subjectCodeList.forEach(i => {
          subjectName.push(
            this.subjectList.find(k => {
              return k.id == i;
            })?.name
          );
        });
        e.subjectName = subjectName.join(' ');
        return e;
      });
    },

    //重置筛选条件
    resetFormData() {
      this.formData = Object.assign(this.formData, {
        resourceName: '', // 资源名称
        pressCode: '', // 出版社code
        subject: '', // 学科
        grade: '', // 年级
        page: 1,
        size: 15,
        total: 0,
        stageCode: 0
      });
      this.getDataList();
    },

    async toggleFilter(value, key) {
      if (key == 'stageCode') {
        this.formData.grade = '';
        this.formData.subject = '';
        await this.getSubjectList(value);
        this.gradeList = [];
        await this.getGradeList(value);
        if (value == -1) {
          // 学前班级
          this.gradeList.push(...this.preschoolList);
        }
      }
      this.formData[key] = value;
      this.formData.total = 0;
      this.formData.page = 1;
      this.getDataList();
    },

    onShowSizeChange(page, size) {
      this.formData.page = this.formData.size != size ? 1 : page;
      this.formData.size = size;
      this.getDataList();
    },

    // 统计接口
    async handleStatistic(action, resourceId) {
      await getResourceStatistic(resourceId, {
        userId: this.userId,
        action
      });
    },

    // 点击浏览
    async handleBrowse(item) {
      this.handleStatistic('0', item.resourceId);
      // 安装包、压缩包不能浏览，只能下载
      const { data } = await getResourceUrl(item.resourceId, { userId: this.userId || null });
      // 文档类：type：6PPT/7WORD/8PDF   音视频 type：1/2/5   压缩包：type：9ZIP   安装包：type：4   H5类 type：3
      if (item.resourceType == 6) {
        const url = `https://play.yunzuoye.net/play?type=5&src=${data.viewUrl}`;
        open(url);
      } else if (item.resourceType == 7) {
        const url = `https://play.yunzuoye.net/play?type=5&src=${data.viewUrl}`;
        open(url);
      } else if (item.resourceType == 8) {
        // window.open(data.viewUrl + '?response-content-type=application/pdf')
        const url = `https://play.yunzuoye.net/play?type=8&src=${data.viewUrl}`;
        open(url);
      } else if (item.resourceType == 1 || item.resourceType == 2 || item.resourceType == 5) {
        const { href } = this.$router.resolve({
          path: '/videoOnlineLearning',
          query: {
            resourceId: item.resourceId
          }
        });
        open(href);
      } else if (item.resourceType == 3) {
        // const url = location.protocol + '//' + location.host + '/#/ossPreview?url=' + encodeURIComponent('https://www.baidu.com/')
        const url = location.protocol + '//' + location.host + '/#/preview?url=' + encodeURIComponent(data.viewUrl ? data.viewUrl : '');
        open(url, '_blank');
      } else if (item.resourceType == 10) {
        open(data.viewUrl);
      }else if (item.resourceType == 11) {
        this.toClassServer({keyType: 'URL', redirectUrl: data.viewUrl})
      }
    },

    // 点击下载
    async handleDownload(item) {
      // if (!this.userId) {
      //   // 未登录，提示用户登录
      //   let info = this.$info({
      //     centered: true,
      //     okText: '我知道了',
      //     okType: 'danger',
      //     icon: h => h('a-icon', { props: { type: 'info-circle' }, attrs: { style: `color: #f6492b` } }, ''),
      //     content: h =>
      //       h('p', {}, [
      //         '您尚未登录，请先',
      //         h(
      //           'span',
      //           {
      //             attrs: { style: `color: #f6492b; cursor: pointer` },
      //             on: {
      //               click: () => {
      //                 info.destroy()
      //                 this.$router.push('/login')
      //               }
      //             }
      //           },
      //           '登录'
      //         )
      //       ])
      //   })
      //   return
      // }
      this.handleStatistic('1', item.resourceId);
      const { data } = await getResourceUrl(item.resourceId, { userId: this.userId || null });
      window.open(data.downloadUrl);
    },

    async showQrcode(item){
      this.handleStatistic('1', item.resourceId);
      const { data } = await getResourceUrl(item.resourceId, { userId: this.userId || null });
      item.img =await QRCode.toDataURL(data.downloadUrl)
    }
  },
  computed: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
* {
  font-family: PingFangSC-Regular;
}
.download {
  min-height: 91%;
}

// .container div.s-group:nth-child(3) span:nth-of-type(7)::after,
// .container div.s-group:nth-child(3) span:nth-of-type(10)::after,
// .container div.s-group:nth-child(3) span:nth-of-type(13)::after {
//   content: '';
//   display: inline-block;
//   width: 2px;
//   height: 16px;
//   background-color: #e6e6e6;
//   margin-left: 32px;
//   vertical-align: bottom;
// }

.container {
  width: 1200px;
  margin: 16px auto;
  background: #fff;
  padding: 16px;

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  .i-search {
    width: 320px;
    height: 32px;
    vertical-align: top;
    /deep/.ant-input {
      border-radius: 4px;
    }
  }

  .rest-form-text {
    float: right;
    padding: 0;
    color: #f6492b;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  // .s-group:nth-child(2) {
  //   margin-left: 90px;
  //   padding-left: 0;
  //   background-color: #f5f5f5;
  //   width: 400px;
  //   // border-radius: 20px;
  //   min-height: 32px !important;
  //   line-height: 32px;
  //   margin-bottom: 10px;
  // }

  // .s-group:nth-child(2) .label {
  //   position: absolute;
  //   top: 0px;
  //   left: -90px;
  // }

  // .s-group:nth-child(2) .form-tag {
  //   width: 80px;
  //   height: 32px;
  //   line-height: 22px;
  //   // background-color: #f5f5f5;
  //   text-align: center;
  //   margin-left: 0;
  //   margin-bottom: 0;
  // }

  // .s-group:nth-child(2) .form-tag:nth-child(3),
  // .s-group:nth-child(2) .form-tag:nth-child(4) {
  //   margin-left: 0px;
  // }

  // .s-group:nth-child(2) .form-tag:nth-child(2) {
  //   border-top-left-radius: 20px;
  //   border-bottom-left-radius: 20px;
  // }
  // .s-group:nth-child(2) .form-tag:nth-child(6) {
  //   border-top-right-radius: 20px;
  //   border-bottom-right-radius: 20px;
  // }

  .s-group {
    padding-left: 72px;
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 24px;
    min-height: 40px;
    line-height: 40px;
    .label {
      width: 56px;
      text-align: justify;
      display: inline-block;
      text-align-last: justify;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0px;
      color: rgba(0, 0, 0, 0.4);
    }
    .press {
      top: 0px;
    }
    .form-tag {
      cursor: pointer;
      margin-left: 16px;
      margin-bottom: 5px;
      padding: 5px 8px;
      white-space: nowrap;
      display: inline-block;
      line-height: 16px;
      color: #666;
    }

    .active {
      line-height: 16px;
      background: rgba(246, 73, 43, 0.1);
      color: #f6492b;
      border-radius: 3px;
    }

    // .newActive {
    //   // border-radius: 20px;
    //   color: #fff;
      // background: linear-gradient(90deg, #fb8056 0%, #f6492b 100%);
    // }

    .pressTag {
      font-size: 18px;
      padding: 8px 16px;
      height: 100%;
      line-height: 18px;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    overflow: visible;
  }

  .content::after {
    content: '';
    width: 358px;
  }

  .item {
    width: 358px;
    height: 150px;
    background: #f5f5f5;
    border-radius: 4px;
    display: flex;
    margin-bottom: 48px;
    position: relative;

    .resourceType {
      position: absolute;
      right: 72px;
      top: 81px;
      width: 80px;
      height: 80px;
      z-index: 9;

      img {
        width: 100%;
      }
    }

    &_left {
      flex: 1;
      padding: 12px 0 0 16px;

      &_tit {
        font-size: 16px;
        color: #333333;
        height: 48px;
        line-height: 24px;
        margin-right: 12px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
      }
      &_cont {
        color: #999;
        font-size: 14px;
        line-height: 14px;
        margin: 12px 0 24px;
        span {
          padding: 0 8px;
          color: #e6e6e6;
        }
      }
      &_count {
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        span {
          display: inline-block;
          background-color: #f6492b;
          border-radius: 4px;
          padding: 5px 8px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
    }

    &_right {
      position: relative;
      width: 116px;

      img {
        position: absolute;
        left: 0;
        top: -8px;
        height: 166px;
        width: 124px;
        border-radius: 4px;
      }
    }
  }

  .q-pagination {
    text-align: center;
  }

  .empty {
    padding: 48px;
    text-align: center;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.container div.s-group:nth-child(n) span:nth-child(n) {
  padding: 5px 8px;
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

/deep/.ant-pagination-options {
  position: relative;
}
.download {
  /deep/ .ant-popover-inner-content{
    padding: 0;
  }
}


</style>
