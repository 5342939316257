<template>
  <div style="font-family: PingFangSC-Light;">
    <div class="img">
      <div class="img_01">
        <img class="img_01_pic" src="../assets/img_banner(1).png" alt />
        <div class="img_01_title">浙江省音像教材网络下载</div>
        <div class="img_01_msg1">适用于小学、初中、高中多学科</div>
        <div class="img_01_msg2">让您轻松、高效掌握学习内容。</div>
        <div v-if="isWindows" class="img_01_btn" @click="download" >
          <img src="../assets/button_banner_2.png" alt class="download_icon" />
          <div class="img_01_btn_msg">Windows版下载</div>
        </div>
        <div class="img_01_moreLoad" @click="moreVersion">
          更多版本
        </div>
      </div>
      <!-- <div style="width:1366px;margin:0 auto">
        <div class="img_02">
          <img class="img_02_pic" src="../assets/array_1.png" alt />
          <div class="img_02_title">丰富的音像教材资源</div>
          <div class="img_02_msg">学科覆盖中小学语文、数学、英语、信息技术、科学、音乐等。</div>
        </div>
        <div class="img_03">
          <img class="img_03_pic" src="../assets/array_2.png" alt />
          <div class="img_03_title">资源内容科学严谨</div>
          <div class="img_03_msg">内容科学严谨，编校层层把关，专家严格审核。</div>
        </div>
        <div class="img_04">
          <img class="img_04_pic" src="../assets/array_3.png" alt />
          <div class="img_04_title">稳定易用</div>
          <div class="img_04_msg">可在线更新，可离线使用；操控简易、稳定、流畅。</div>
        </div>
      </div> -->

      <div class="bg_download" style="width:100%;height:356px">
        <div style="width:1366px;margin:0 auto" >
          <div class=" wrapImg" style="margin-left:148px">
            <img src="../assets/bg_download1.png" alt="">
            <div class="wrapImg_message">教材资源丰富 </div>
          </div>
          <div class=" wrapImg" style="margin-left:160px">
            <img src="../assets/bg_download2.png" alt="">
            <div class="wrapImg_message">内容形式多样</div>
          </div>
          <div class=" wrapImg" style="margin-left:160px">
            <img src="../assets/bg_download3.png" alt="">
            <div class="wrapImg_message">操作简洁方便</div>
          </div>
        </div>
      </div>

    </div>
<!--    <footer-black @toOpen="toOpen" @toWebOpen="toWebOpen" />-->
    <Footer />
     <!-- 弹出许可证框 -->
    <!-- 弹出许可证框 -->
    <div class="popup" v-if="ispopup" @click="closePic">
      <div class="popup_img">
        <img src="../assets/Fill 3.png" alt class="close" />
        <img @click.stop="clicks" src="../assets/pic-1.jpg" alt />
      </div>
    </div>
    <div class="popup" v-if="isWebPopup" @click="closePic">
      <div class="popup_img">
        <img src="../assets/Fill 3.png" alt class="close" />
        <img @click.stop="clicks" src="../assets/pic-2.jpg" alt />
      </div>
    </div>
    <!-- 更多版本 -->
    <div class="moreLoad" v-if="isMoreLoad">
      <div class="moreLoad_version">
        <div class="moreLoad_version_close" @click="moreVersion">
          <img src="../assets/Close.png" alt="">
        </div>
        <div class="moreLoad_version_title">更多版本</div>
        <div v-for="(item,index) in versionList" :key="index" class="versionList" @click="downVersion(index)">
          {{item.name}}</div>
      </div>
    </div>
    <div class="attentionBox">
      <div v-show="isShow" class="attentionPic">
        <img src="../assets/wecom-temp-441ae10872831861cf94fd4d30e4514a.png" alt="">
        <span></span>
      </div>
      <!-- <div @mouseenter="isShow = true" @mouseleave="isShow = false" class="text">
        <span>
          扫码关注
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
export default {
  name: "Material",
  components: {
    Footer
  },
  data() {
    return {
      agent: "",
      isMac: "",
      ispopup: false,
      isWebPopup: false,
      isWindows: true,
      isMoreLoad: false,
      isShow: false,
      versionList: [
        {
          name: "Windows64版下载"
        },
        {
          name: "Windows32版下载"
        }
        // {
        //   name:'Mac版下载'
        // }
      ]
    }
  },
  methods: {
    clicks() {},
    moreVersion() {
      this.isMoreLoad = this.isMoreLoad == false ? true : false
    },
    download() {
      // if(this.agent) {
      //   this.$axios.get("/api/v1/pub/clientVersion/new"
      //   ).then(res=>{
      //     console.log(res.data)
      //     window.location.href = res.data.winUrl
      //   })
      // }
      // else{
      //   this.$axios.get("/api/v1/pub/clientVersion/new"
      //   ).then(res=>{
      //     console.log(res.data)
      //     window.location.href = res.data.macUrl
      //   })
      // }
      if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          console.log(res.data)
          window.location.href = res.data.win32Url
        })
      } else if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          console.log(res.data)
          window.location.href = res.data.winUrl
        })
      } else if (this.isMac) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          window.location.href = res.data.winUrl
        })
      } else {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.winUrl
        })
      }
    },

    OSnow() {
      this.agent = navigator.userAgent.toLowerCase()
      this.isMac = /macintosh|mac os x/i.test(navigator.userAgent)

      if (this.agent.indexOf("win32") >= 0 || this.agent.indexOf("wow32") >= 0) {
        // console.log("这是windows32位系统")
        this.isWindows = true
      }
      if (this.agent.indexOf("win64") >= 0 || this.agent.indexOf("wow64") >= 0) {
        console.log("这是windows64位系统")
        this.isWindows = true
      }
      if (this.isMac) {
        this.isWindows = false
      }
    },
    //选择下载版本
    downVersion(index) {
      console.log(index)
      if (index == 0) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.winUrl
        })
      } else if (index == 1) {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.win32Url
        })
      } else {
        this.$axios.get("/api/v1/pub/clientVersion/new").then(res => {
          // console.log(res.data);
          window.location.href = res.data.winUrl
        })
      }
    },

    closePic() {
      this.ispopup = false
      this.isWebPopup = false
    },

    toOpen(data) {
      this.ispopup = data
    },
    toWebOpen(data) {
      this.isWebPopup = data
    }
  },
  mounted() {
    this.OSnow()
  }
}
</script>

<style lang="less" >
.attentionBox {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: 72px;
  .attentionPic {
    position: relative;
    height: 116px;
    width: 116px;
    background-color: rgb(247, 50, 16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    img {
      width: 80%;
      height: 80%;
    }
    span {
      position: absolute;
      right: -6px;
      border-top: 6px solid rgb(247, 50, 16);
      border-right: 6px solid rgb(247, 50, 16);
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      transform: rotate(45deg)
    }
  }
  .text {
    height: 115px;
    margin-left: 15px;
    background-color: rgb(247, 50, 16);
    border-radius: 5px 0 0 5px;
    padding: 8px;
    width: 41px;
    cursor: pointer;
    span {
      display: block;
      color: #fff;
      word-break: break-all;
      overflow-wrap: break-word;
      width: 14px;
      padding: 0 6px;
    }
  }
}
.img {
  width: 100%;
  &_01 {
    width: 100%;
    position: relative;
    height: 547px;
    background-color: #fff9f8;
    &_pic {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    &_title {
      position: absolute;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      font-size: 28px;
      color: #303035;
      top: 176px;
      left: 61.3%;
    }
    &_msg1 {
      width: 346px;
      height: 68px;
      font-family: PingFangSC-Light;
      font-size: 16px;
      color: #303035;
      line-height: 35px;
      position: absolute;
      top: 233px;
      left: 61.3%;
    }
    &_msg2 {
      width: 346px;
      height: 68px;
      font-family: PingFangSC-Light;
      font-size: 16px;
      color: #595959;
      line-height: 35px;
      position: absolute;
      top: 272px;
      left: 61.3%;
    }
    &_btn {
      position: absolute;
      top: 325px;
      left: 61.3%;
      background: #f6492b;
      border-radius: 7px;
      color: #ffffff;
      height: 54px;
      width: 237px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.2s ease-in;
      &:hover {
        transform: translateY(-2px);
        box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.2);
      }
      &_img {
        border-radius: 2px;
        position: absolute;
      }
      &_msg {
        position: absolute;
        // left: 87px;
        //临时改的
        top: 50%;
        transform: translateY(-50%);
        left: 68px;
        font-size: 17px;
      }
    }
    &_moreLoad {
      color: #303035;
      position: absolute;
      top: 325px;
      left: 61.3%;
      margin-top: 68px;
      padding-bottom: 1px;
      cursor: pointer;
      border-bottom: 1px solid #303035;
    }
  }
  &_02 {
    width: 100%;
    height: 545px;
    position: relative;
    &_pic {
      position: absolute;
      top: 99px;
      left: 12.7%;
    }
    &_title {
      position: absolute;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      font-size: 32px;
      color: #303035;
      top: 215px;
      left: 58.3%;
    }
    &_msg {
      width: 346px;
      height: 68px;
      font-family: PingFangSC-Light;
      font-size: 21px;
      color: #303035;
      line-height: 35px;
      position: absolute;
      top: 272px;
      left: 58.3%;
    }
  }
  &_02:after {
    content: "";
    overflow: hidden;
  }

  &_03 {
    width: 100%;
    height: 547px;
    position: relative;
    &_pic {
      position: absolute;
      top: 122px;
      left: 50%;
    }
    &_title {
      position: absolute;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      font-size: 32px;
      color: #303035;
      top: 253px;
      left: 18.6%;
    }
    &_msg {
      width: 346px;
      height: 68px;
      font-family: PingFangSC-Light;
      font-size: 21px;
      color: #303035;
      line-height: 35px;
      position: absolute;
      top: 310px;
      left: 18.6%;
    }
  }
  &_03:after {
    content: "";
    overflow: hidden;
  }
  &_04 {
    width: 100%;
    height: 545px;
    position: relative;
    &_pic {
      position: absolute;
      top: 99px;
      left: 12.7%;
    }
    &_title {
      position: absolute;
      font-family: PingFangSC-Semibold;
      font-weight: bold;
      font-size: 32px;
      color: #303035;
      top: 215px;
      left: 58.3%;
    }
    &_msg {
      width: 346px;
      height: 68px;
      font-family: PingFangSC-Light;
      font-size: 21px;
      color: #303035;
      line-height: 35px;
      position: absolute;
      top: 272px;
      left: 58.3%;
    }
  }
  &_04:after {
    content: "";
    overflow: hidden;
  }
  .bg_download {
    .wrapImg {
      display: inline-block;
      width: 250px;
      margin-top: 56px;
      &_message {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 72px;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #37373d;
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  &_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
      position: absolute;
      cursor: pointer;
      top: 14px;
      right: 14px;
    }
  }
}
.moreLoad {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  &_version {
    width: 432px;
    height: 240px;
    border-radius: 4px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &_close {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }
    &_title {
      margin: 32px auto 24px;
      width: 330px;
      height: 24px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      text-align: center;
      line-height: 24px;
    }
    .versionList {
      margin: 0 auto 12px;
      width: 290px;
      height: 32px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      line-height: 32px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #666666;
      cursor: pointer;
      &:hover {
        background-color: #f6492b;
        border-color: #f6492b;
        color: #fff;
      }
    }
  }
}
</style>
