<template>
  <a-popover :visible="visible" placement="bottom" trigger="click">
    <div slot="content" class="sms-img" style="width:200px">
      <span class="time" v-show="time">{{time}}</span>
      <img :src="smsImg" />
      <div class="sms-img-form">
        <div class="ubdo" @click="refreshSmsImg">
          <a-icon type="undo" />
        </div>
        <a-input @pressEnter="sendSmsCode" v-model="captchaCode" />
        <button @click="sendSmsCode" :class="{disabled:!!time}">确定</button>
      </div>
    </div>
    <a-button @click="openSmsImg">获取验证码</a-button>
  </a-popover>
</template>
<script>
import {getuserImg,getMobileCode,checkMoblie} from "../api/user"
export default {
  data: function() {
    return {
      smsImg: "",
      visible: false,
      captchaCode: "",
      time: ''
    };
  },
  props: ["usage", "validateFields"],
  methods: {
    getSmsImg(mobile) {
      getuserImg({ usage: this.usage, mobile, t: new Date().getTime() })
        .then(res => (this.smsImg = res));
    },
    refreshSmsImg() {
      this.validateFields(["mobile"], (err, value) => {
        if (!err) this.getSmsImg(value.mobile);
      });
    },
    openSmsImg() {
      this.validateFields(["mobile"], async (err, value) => {
        if (!err) {
          let res = await checkMoblie({ mobile: value.mobile });
          if (res.data && this.usage == 1) {
            this.$message.error("该手机号已注册！");
            return;
          } else if (!res.data && this.usage == 2) {
            this.$message.error("该手机号未注册！");
            return;
          }
          this.visible = !this.visible;
          if (this.visible) this.getSmsImg(value.mobile);
        }
      });
    },
    sendSmsCode(e) {
      if(this.time) return  
      this.validateFields(["mobile"], (err, value) => {
        if (!err) {
          getMobileCode({
              ...value,
              usage: this.usage,
              explosionProof: true,
              captchaCode: this.captchaCode
            })
            .then(() => {
              this.visible = false;
              this.$message.success("发送成功");
              this.time = 60
              let a = setInterval(() => {
                  this.time = this.time - 1
                  if(!this.time) clearInterval(a)
              }, 1000);
            });
        }
      });
    },
    changeVisible(visible) {
      this.visible = visible;
    }
  }
};
</script>
<style scoped lang="less">
.sms-img {
  width: 300px;
  -webkit-app-region: no-drag;
  .time {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.45);
    color: white;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    right: 20px;
  }
  .sms-img-form {
    margin-top: 10px;
    position: relative;
    .ubdo {
      cursor: pointer;
      width: 48px;
      height: 48px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      display: inline-block;
      margin-right: 10px;
      text-align: center;
      line-height: 48px;
    }
    /deep/ .ant-input {
      width: 138px;
      height: 48px;
      vertical-align: top;
    }
    button {
      top: 4px;
      right: 0;
      position: absolute;
      width: 44px;
      height: 40px;
      color: #f73210;
      border: 1px solid #f73210;
      background: white;
      border-radius: 4px;
      &.disabled{
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
}
</style>