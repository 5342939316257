let area = {
    "330000": {
		"name": "浙江省",
		"child": {
			"330100": {
				"name": "杭州市",
				"child": {
					"330102": "上城区",
					"330103": "下城区",
					"330104": "江干区",
					"330105": "拱墅区",
					"330106": "西湖区",
					"330108": "滨江区",
					"330109": "萧山区",
					"330110": "余杭区",
					"330111": "富阳区",
					"330122": "桐庐县",
					"330127": "淳安县",
					"330182": "建德市",
					"330185": "临安市"
				}
			},
			"330200": {
				"name": "宁波市",
				"child": {
					"330203": "海曙区",
					"330204": "江东区",
					"330205": "江北区",
					"330206": "北仑区",
					"330211": "镇海区",
					"330212": "鄞州区",
					"330225": "象山县",
					"330226": "宁海县",
					"330281": "余姚市",
					"330282": "慈溪市",
					"330283": "奉化市"
				}
			},
			"330300": {
				"name": "温州市",
				"child": {
					"330302": "鹿城区",
					"330303": "龙湾区",
					"330304": "瓯海区",
					"330305": "洞头区",
					"330324": "永嘉县",
					"330326": "平阳县",
					"330327": "苍南县",
					"330328": "文成县",
					"330329": "泰顺县",
					"330381": "瑞安市",
					"330382": "乐清市"
				}
			},
			"330400": {
				"name": "嘉兴市",
				"child": {
					"330402": "南湖区",
					"330411": "秀洲区",
					"330421": "嘉善县",
					"330424": "海盐县",
					"330481": "海宁市",
					"330482": "平湖市",
					"330483": "桐乡市"
				}
			},
			"330500": {
				"name": "湖州市",
				"child": {
					"330502": "吴兴区",
					"330503": "南浔区",
					"330521": "德清县",
					"330522": "长兴县",
					"330523": "安吉县"
				}
			},
			"330600": {
				"name": "绍兴市",
				"child": {
					"330602": "越城区",
					"330603": "柯桥区",
					"330604": "上虞区",
					"330624": "新昌县",
					"330681": "诸暨市",
					"330683": "嵊州市"
				}
			},
			"330700": {
				"name": "金华市",
				"child": {
					"330702": "婺城区",
					"330703": "金东区",
					"330723": "武义县",
					"330726": "浦江县",
					"330727": "磐安县",
					"330781": "兰溪市",
					"330782": "义乌市",
					"330783": "东阳市",
					"330784": "永康市"
				}
			},
			"330800": {
				"name": "衢州市",
				"child": {
					"330802": "柯城区",
					"330803": "衢江区",
					"330822": "常山县",
					"330824": "开化县",
					"330825": "龙游县",
					"330881": "江山市"
				}
			},
			"330900": {
				"name": "舟山市",
				"child": {
					"330902": "定海区",
					"330903": "普陀区",
					"330921": "岱山县",
					"330922": "嵊泗县"
				}
			},
			"331000": {
				"name": "台州市",
				"child": {
					"331002": "椒江区",
					"331003": "黄岩区",
					"331004": "路桥区",
					"331021": "玉环县",
					"331022": "三门县",
					"331023": "天台县",
					"331024": "仙居县",
					"331081": "温岭市",
					"331082": "临海市"
				}
			},
			"331100": {
				"name": "丽水市",
				"child": {
					"331102": "莲都区",
					"331121": "青田县",
					"331122": "缙云县",
					"331123": "遂昌县",
					"331124": "松阳县",
					"331125": "云和县",
					"331126": "庆元县",
					"331127": "景宁畲族自治县",
					"331181": "龙泉市"
				}
			}
		}
	}
}
function formatArea(area) {
    let areaList = [
      {
        value: "330000,浙江省",
        label: "浙江省",
        children: []
      }
    ];
    let addList = area["330000"].child;
    for (let key in addList) {
      let children = [];
      for (let areaCode in addList[key].child) {
        children.push({
          value: areaCode + "," + addList[key].child[areaCode],
          label: addList[key].child[areaCode]
        });
      }
      areaList[0].children.push({
        value: key + "," + addList[key].name,
        label: addList[key].name,
        children
      });
    }
    return areaList;
  }


export default formatArea(area)