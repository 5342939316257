import base from './base';
import axios from './request';

function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
            reject(new Error('文件流异常'));
        };
    });
}

export const login = (params) => {
    params.platform = "WEB";
    params.terminalDeviceId = localStorage.getItem('terminalDeviceId') || ( Date.now() +'now'+ Math.random()*1000 +'tag');
    localStorage.setItem('terminalDeviceId', params.terminalDeviceId);
    return axios.post(`${base}/api/v1/pub/login`, params);
}
export const register = (params) => {
    params.platform = "WEB";
    return axios.post(`${base}/api/v1/pub/user`, params);
}
export const getLoginUrl = (params) => {
    return axios.get(`${base}/api/v1/pub/zjer/login`, params)
}

export const getuserImg = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${base}/api/v1/pub/user/verificationCode`, { params, responseType: 'blob', emulateJSON: true }).then(res => {
            blobToBase64(res.data).then(res => {
                resolve(res)
            })
        })
    })
}
export const getuserImgNoPhone = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${base}/api/v2/pub/user/verificationCode`, { params, responseType: 'blob', emulateJSON: true }).then(res => {
            blobToBase64(res.data).then(res => {
                resolve(res)
            })
        })
    })
}

export const checkMoblie = (params) => {
    return axios.get(`${base}/api/v1/pub/user/check/mobile`, { params })
}

export const getMobileCode = (params) => {
    return axios.get(`${base}/api/v1/pub/user/mobile/code`, { params })
}
export const getMobileCodeV2 = (params) => {
    return axios.get(`${base}/api/v2/pub/user/mobile/code`, { params })
}
export const restPassword = (params) => {
    return axios.post(`${base}/api/v1/pub/user/restPassword`, params)
}
export const getGrade = () => {
    return axios.get(`${base}/api/v1/dictionary/grade`)
}

export const getSubject = () => {
    return axios.get(`${base}/api/v1/dictionary/subject`)
}

export const complete = (params) => {
    return axios.post(`${base}/api/v1/user/complete`, params)
}


export const completed = (userId) => {
    return axios.get(`${base}/api/v1/users/${userId}/completed`)
}

export const getuserConfig = () => {
    return axios.get(`${base}/api/v2/userConfig`)
}

export const getZjerUser = (params)=>{
    return axios.get(`${base}/api/v1/pub/zjer/user`, {params})
}
export const bindZjerUser = (params)=>{
 return axios.post(`${base}/api/v1/zjer/binding`, params)
}
export const zjerLogin = (params)=>{
    params.platform = "WEB";
    return axios.post(`${base}/api/v1/pub/login/zjer`, params)
}
//获取用户信息
export const getUser = (userId)=>{
    return axios.get(`${base}/api/v1/users/${userId}`)
}

export const getPrizeRecode = (userId,params)=>{
  return axios.get(`${base}/api/v1/pub/activation/prizeRecode/web/${userId}`,{params})
}
export const getZjyConfig = () => {
  return axios.get(`${base}/api/v1/zjy/userConfig`)
}

export const updateMobile = (userId,params)=>{
  params.platform = "WEB";
  return axios.post(`${base}/api/v1/users/${userId}/mobile/update`, params)
}

//验证用户身份密码
export const verifyPassword = (userId,params)=>{
  return axios.post(`${base}/api/v1/users/${userId}/password/verify`, params)
}