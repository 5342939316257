<template>
  <div class="PrizeRecode">
    <a-table rowKey="id" :pagination="false" size="middle" :columns="columns" :data-source="data" bordered>
      <template slot="userAddress" slot-scope="text, record">
        <div class="info">
          <div class="info-name">{{ `${record.userName} ${record.userPhone}` }}</div>
          <div class="info-address">{{ record.userAddress }}</div>
        </div>
      </template>
    </a-table>
    <a-pagination @showSizeChange="onShowSizeChange" :page-size.sync="pagination.size" showQuickJumper show-size-changer
      v-if="pagination.total" v-model="pagination.page" class="pagination" :total="pagination.total" @change="onChange" />
  </div>
</template>

<script>
import { getPrizeRecode } from '@/api/user'
import { mapGetters } from 'vuex'
import moment from 'moment'
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    className: 'column',
    width: 80,
    align: 'center',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '奖品名称',
    dataIndex: 'prizeName',
    key: 'prizeName',
    className: 'column',
  },
  {
    title: '类型',
    dataIndex: 'prizeType',
    key: 'prizeType',
    className: 'column',
    customRender: (text, record, index) => ({ 1: '实体商品', 2: '文件类', 3: '虚拟奖品' }[text])

  },
  {
    title: '领奖信息',
    dataIndex: 'userAddress',
    dataIndex: 'userAddress',
    className: 'column',
    scopedSlots: { customRender: 'userAddress' },
  },
  {
    title: '领取时间',
    dataIndex: 'createTime',
    key: 'createTime',
    className: 'column',
    customRender: (text, record, index) => text && moment(text).format('YYYY/MM/DD HH:mm:ss')
  },
]

export default {
  data() {
    return {
      pagination: { page: 1, size: 10, total: 0 },
      columns,
      data: []
    }
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
  },
  methods: {
    onShowSizeChange(current, pageSize) {
      this.pagination.page = 1
      this.pagination.size = pageSize
      this.getPrizeRecode()
    },
    async getPrizeRecode() {
      const { prizeRecordDTOList, totalCount } = (await getPrizeRecode(this.user.userId, this.pagination)).data
      this.data =prizeRecordDTOList
      this.pagination.total = totalCount
    },
    onChange(pageNumber) {
      this.pagination.page = pageNumber
      this.getPrizeRecode()
    },
  },
  mounted() {
    this.pagination.total = this.data.length
    this.getPrizeRecode()
  },

}
</script>

<style lang='less' scoped>
.PrizeRecode {
  padding: 32px;

  /deep/th.column {
    text-align: center !important;
    background-color: #f7f7fa !important;

    font-size: 14px;
    color: #333333;
    font-weight: 600;
  }

  /deep/td.column {
    // padding: 33px 20px !important;
  }

  .pagination {
    padding-top: 32px;
    text-align: center;
  }

  .info {
    font-size: 14px;
    color: #666666;
    font-weight: 400;

    &-name {
      margin-bottom: 6px;
    }

    &-address {

      line-height: 18px;
    }
  }
}</style>