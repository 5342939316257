<script lang="jsx">
import { getDeepLeafs } from '@/util/index.js'
export default {
  name: 'deep',
  props: {
    keyName: {
      default: 'id',
      type: String
    },
    folderList: {
      default() {
        return []
      },
      type: Array
    },
    selectedTree: {
      default() {
        return []
      },
      type: Array
    },
    defaultExpandedKeys: {
      default() {
        return []
      },
      type: Array
    },
    filterIds: {
      default() {
        return []
      },
      type: Array
    },
    showEditing: {
      default() {
        return false
      },
      type: Boolean
    },
    deepLeafs: {
      type: Array,
      require: false
    },
    iconType: {
      type: String,
      default: 'folder',
      require: false
    }
  },
  computed: {
    showLine() {
      return this.iconType == 'catalog'
    }
  },
  render(h) {
    let propsData
    const icArrowDown = require('@/icons/svg/ic_arrow_down.svg')
    const catalogJian = require('@/icons/svg/catalog-jian.svg')
    const catalogJia = require('@/icons/svg/catalog-jia.svg')
    const icFolder = require('@/icons/svg/ic_folder.svg')

    const deepChapter = folderList => {
      return folderList.map(item => {
        if (!this.filterIds.includes(item[this.keyName])) {
          if (item.props) {
            propsData = item.props
          }
          if (item.childList && item.childList.length > 0) {
            //  let name = (
            //     <div>
            //        <span>{item.name||item.catalogName}</span>
            //         <button style={{marginLeft:10}} onClick={e =>{
            //           e.stopPropagation();
            //           alert(item[this.keyName])}}>测试按钮</button>
            //         <button  style={{marginLeft:10}} onClick={() => this.onSave(item.key)}/>
            //     </div>
            //   )
            let isExpand = this.firstExpand ? this.defaultExpandedKeys.includes(item[this.keyName]) : this.expandedKeys.includes(item[this.keyName])

            return (
              <a-tree-node class={this.showEditing ? 'editing-tree' : ''} disabled={item.disabled} key={item[this.keyName]} value={propsData} level={item.level} style={this.style} isLeaf={false} data-parents={item.keyList ? item.keyList.join('-') : ''}>
                <template slot="switcherIcon">{this.iconType == 'folder' ? <img src={icArrowDown} /> : isExpand ? <img src={catalogJian} /> : <img src={catalogJia} />}</template>
                <template slot="icon">{this.iconType == 'folder' && <img src={icFolder} />}</template>
                <div class="tree-title" slot="title">
                  {this.$scopedSlots.title ? (
                    this.$scopedSlots.title({ item })
                  ) : (
                    <div class="title-text" title={item.name || item.catalogName}>
                      {item.name || item.catalogName}
                    </div>
                  )}
                  {this.$scopedSlots.subTitle && this.$scopedSlots.subTitle({ item })}
                  {this.$scopedSlots.handle && this.$scopedSlots.handle({ item })}
                  {this.showEditing ? (
                    <div class="edit-operations">
                      <icon-svg icon="tree_add" title="添加兄弟节点" class="icon" nativeOnClick={e => this.edit('add', item, e)} />
                      {item.level < 4 ? <icon-svg icon="tree_add_sub" title="添加子节点" class="icon" nativeOnClick={e => this.edit('addSub', item, e)} /> : ''}
                      <icon-svg icon="edit" title="重命名" class="icon" nativeOnClick={e => this.edit('rename', item, e)} />
                      <icon-svg icon="tree_remove" title="删除" class="icon" nativeOnClick={e => this.edit('remove', item, e)} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {deepChapter(item.childList)}
              </a-tree-node>
            )
          } else {
            //异步加载子节点
            let icon = null
            if (item.hasOwnProperty('expand') && item.expand == false) {
              icon = <img src={catalogJia} />
            } else {
              icon = <img src={catalogJian} />
            }
            return (
              <a-tree-node class={this.showEditing ? 'editing-tree' : ''} key={item[this.keyName]} value={propsData} level={item.level} style={this.style} isLeaf={true} data-parents={item.keyList ? item.keyList.join('-') : ''}>
                <template slot="switcherIcon">{this.iconType != 'folder' && icon}</template>
                <template slot="icon">{this.iconType == 'folder' && <img src={icFolder} />}</template>
                <div class="tree-title" slot="title">
                  {this.$scopedSlots.title ? (
                    this.$scopedSlots.title({ item })
                  ) : (
                    <div class="title-text" title={item.name || item.catalogName}>
                      {item.name || item.catalogName}
                    </div>
                  )}
                  {this.$scopedSlots.subTitle && this.$scopedSlots.subTitle({ item })}
                  {this.$scopedSlots.handle && this.$scopedSlots.handle({ item })}
                  {this.showEditing ? (
                    <div class="edit-operations">
                      <icon-svg icon="tree_add" title="添加兄弟节点" class="icon" nativeOnClick={e => this.edit('add', item, e)} />
                      {item.level < 4 ? <icon-svg icon="tree_add_sub" title="添加子节点" class="icon" nativeOnClick={e => this.edit('addSub', item, e)} /> : ''}
                      <icon-svg icon="edit" title="重命名" class="icon" nativeOnClick={e => this.edit('rename', item, e)} />
                      <icon-svg icon="tree_remove" title="删除" class="icon" nativeOnClick={e => this.edit('remove', item, e)} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </a-tree-node>
            )
          }
        }
      })
    }
    return (
      <a-directory-tree
        expandedKeys={this.firstExpand ? this.defaultExpandedKeys : this.expandedKeys}
        show-icon={true}
        autoExpandParent={true}
        selectedKeys={this.selectedTree}
        checkedKeys={this.selectedTree}
        onSelect={this.selectTree}
        onExpand={this.expand}
        showLine={this.showLine}
        show-icon={!this.showLine}
        // draggable
        // onDragenter="onDragEnter"
        // onDrop="onDrop"
        class={this.showLine ? 'catalog-tree' : ''}
      >
        {deepChapter(this.folderList)}
      </a-directory-tree>
    )
  },
  data() {
    return {
      expandedKeys: [],
      firstExpand: true
    }
  },
  mounted() {
    if (this.defaultExpandedKeys.length) {
      this.expandedKeys = [].concat(this.defaultExpandedKeys)
    }
    this.modifyHeight()
    if (this.deepLeafs != undefined && Array.isArray(this.deepLeafs) && this.selectedTree.length > 0) {
      this.$emit('update:deepLeafs', getDeepLeafs(this.folderList, this.selectedTree[0], this.keyName))
    }
  },
  updated() {},
  methods: {
    //  onDragEnter(info) {
    //   console.log(info);
    //   // expandedKeys 需要受控时设置
    //   // this.expandedKeys = info.expandedKeys
    // },
    // onDrop(info) {
    //   console.log(info);

    // },
    // 展开按钮
    expand(expandedKeys) {
      this.firstExpand = false
      this.expandedKeys = expandedKeys
      this.$emit('update:defaultExpandedKeys', this.expandedKeys)
    },
    getStyle(obj, attr) {
      if (obj.currentStyle) {
        return obj.currentStyle[attr]
      } else {
        return getComputedStyle(obj, false)[attr]
      }
    },
    // 每次折叠页面时，dom将会重新渲染，这会导致代码修改的属性会覆盖。故在此添加此方法
    modifyHeight() {
      this.$nextTick(() => {
        let unhandleArray = document.getElementsByClassName('ant-tree-node-content-wrapper')
        for (let item of unhandleArray) {
          let height = parseInt(this.getStyle(item, 'height'))
          if (height > 32) {
            item.setAttribute('twoLine', '1')
          }
        }
      })
    },
    selectTree(params, e) {
      let level = e.node.$props.pos.split('-').length - 1
      this.$emit('selectTree', params, e, level)
      if (this.deepLeafs != undefined && Array.isArray(this.deepLeafs) && this.selectedTree.toString() != params.toString()) {
        this.$emit('update:deepLeafs', getDeepLeafs(this.folderList, params, this.keyName))
      }
    },
    edit(operation, item, e) {
      e.stopPropagation()
      this.$emit('edit', operation, item, getDeepLeafs(this.folderList, [item.id], this.keyName))
    }
  }
}
</script>

<style lang="less">
@import '~@/assets/css/theme.less';

.ant-tree-title {
  width: 100%;
  max-width: unset;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  max-height: 40px;
  line-height: 36px;
  height: auto;
  white-space: normal !important;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  vertical-align: middle;
  word-wrap: break-word;
  font-size: 14px;
}
.ant-tree li {
  padding: 0;
}
.ant-tree li .ant-tree-node-content-wrapper {
  width: calc(100% - 22px);
  height: 36px !important;
  display: inline-flex;
  align-items: center;
}
.ant-tree.ant-tree-directory > li span.ant-tree-switcher,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher {
  margin-top: 4px;
}
.ant-tree-node-content-wrapper-normal {
  height: 36px !important;
  vertical-align: middle;
}

.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper::before {
  height: 36px !important;
}
.ant-tree-iconEle {
  margin-right: 5px !important;
  vertical-align: middle !important;
}
.ant-tree-child-tree > li:first-child {
  padding-top: 0px !important;
}
.tree {
  overflow-y: auto;
  min-height: 895px;
  max-height: 895px;
}
.ant-tree-switcher {
  .a-i {
    width: 10px;
    height: 10px;
    transition: transform 0.2s;
  }
}
.ant-tree-switcher_close {
  .a-i {
    transform: rotate(-90deg);
  }
}
.ant-tree-switcher_open {
  .a-i {
    filter: saturate(2);
  }
}
.ant-tree.ant-tree-show-line li span.ant-tree-switcher {
  background: transparent;
}
.ant-tree.ant-tree-show-line li:not(:last-child)::before {
  border-left-style: dashed !important;
  height: 100%;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #f0f7ff;
}
.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #f6492b !important;
  background-color: transparent !important;
}
.ant-tree li .ant-tree-node-content-wrapper {
  transition: 0.2s;
}
.editing-tree {
  .ant-tree-title {
    max-width: 420px;
  }
}

.tree-title {
  display: flex;
  align-items: center;
  .title-text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sub-title {
    max-width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 12px;
  }
  .handle {
    font-size: 12px;
    color: #f6492b;
    line-height: 22px;
    position: absolute;
    right: 0;
  }
  .edit-operations {
    visibility: hidden;
    height: 36px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    .icon + .icon {
      margin-left: 8px;
    }
  }
  &:hover {
    .edit-operations {
      visibility: visible;
    }
  }
}

.ant-tree.catalog-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.catalog-tree .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.catalog-tree > li span.ant-tree-node-content-wrapper:hover::before,
.ant-tree.catalog-tree .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: transparent !important;
  border-right: 0;
}
.ant-tree > li:first-child {
  padding-top: 0;
}
.ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background-color: transparent !important;
}
</style>