<template>
  <div style="margin-bottom: 278px;">
    <div class="user">
      <div class="menu">
        <div class="menu-title">
          个人设置
        </div>
        <div class="item" :class="{ 'active': active == 0 }" @click="active=0">
          <img :src="active==0?ic_lock_sel:ic_lock_nor" alt="">
          基本信息</div>
        <div class="item" :class="{ 'active': active == 1 }" @click="active=1">
          <img :src="active==1?ic_mine_sel:ic_mine_nor" alt="">
          修改密码</div>
        <div class="item" :class="{ 'active': active == 4 }" @click="active=4">
          <img :src="active==4?ic_gift_sel:ic_gift_nor" alt="">
          奖品兑换记录</div>
        <div class="item" :class="{ 'active': active == 5 }" @click="active=5">
          <img :src="active==5?ic_feed_sel:ic_feed_nor" alt="">
          意见反馈</div>

        <!-- <div class="item" :class="{'active':active==2}" @click="$router.push('/collect')">我的收藏</div>
              <div class="item" :class="{'active':active==3}" >我的授权</div> -->
      </div>
      <div style="height: 100%;background-color: #fff;flex:1">
        <userInfo v-if="active==0"></userInfo>
        <EditPassword v-if="active==1"></EditPassword>
        <prizeRecode v-if="active==4"></prizeRecode>
        <FreeBack v-if="active==5"></FreeBack>
      </div>
    </div>
      <Footer class="user-foot"></Footer>
  </div>
</template>
<script>
import ic_lock_nor from '@/assets/icon/ic_lock_nor.svg'
import ic_lock_sel from '@/assets/icon/ic_lock_sel.svg'
import ic_mine_nor from '@/assets/icon/ic_mine_nor.svg'
import ic_mine_sel from '@/assets/icon/ic_mine_sel.svg'
import ic_gift_nor from '@/assets/icon/ic_gift_nor.svg'
import ic_gift_sel from '@/assets/icon/ic_gift_sel.svg'
import ic_feed_sel from '@/assets/icon/ic_feedback_sel.svg'
import ic_feed_nor from '@/assets/icon/ic_feedback_nor.svg'
import prizeRecode from '@/views/prizeRecode'
import EditPassword from '@/views/EditPassword.vue'
import userInfo from '@/views/userInfo.vue'
import Footer from '@/components/Footer';
import FreeBack from '@/views/profile/freeback.vue'
export default {
  components:{prizeRecode, EditPassword, userInfo,Footer, FreeBack},
  data() {
    return {
      active: 0,
      ic_lock_nor,
      ic_lock_sel,
      ic_mine_nor,
      ic_mine_sel,
      ic_gift_nor,
      ic_gift_sel,
      ic_feed_sel,
      ic_feed_nor,
    }
  },
  mounted() {
    // if (window.location.href.indexOf('editPassword') > 0) this.active = 1
    // if (window.location.href.indexOf('collect') > 0) this.active = 2
    if (!localStorage.user) this.$router.push('/loginRegistration?loginStep=1')
  }
}
</script>
<style lang='less' scoped>
.user-foot{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 11;
}
.user {
  min-height: 550px;
  height: calc(~"100vh - 386px");
  width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 24px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  .menu {
    width: 242px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #eee;
    margin-right: 16px;

    &-title {
      padding-left: 24px;
      font-size: 14px;
      color: #999999;
      letter-spacing: 0;
      line-height: 54px;
      font-weight: 500;
    }

    .item {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      letter-spacing: 0;
      padding-left: 24px;
      font-weight: 400;
      line-height: 48px;
      margin-bottom: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      >img {
        margin-right: 8px;
      }
      &.active {
        color: #F6492B;
        background: rgba(246, 73, 43, 0.10);
      }
    }
  }

  /deep/ .ant-input {
    height: 48px;
  }
}</style>
