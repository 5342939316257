<template>
  <div class="main">
    <div class="filter-box">
      <div class="filter-item">
        <span class="title">学段：</span>
        <span class="item" :class="{ active: stageId === 2 }" @click="stageId = 2">初中</span>
        <span class="item" :class="{ active: stageId === 3 }" @click="stageId = 3">高中</span>
      </div>
      <div class="filter-item">
        <span class="title">学科：</span>
        <span v-show="stageId === 2">
          <span class="item" :class="{ active: item.id === subjectId }" v-for="item of subject" :key="item.id"
            @click="subjectId = item.id">{{ item.name }}</span>
        </span>
      </div>
      <div class="filter-item">
        <span class="title">地区：</span>
        <span v-show="stageId === 2">
          <span class="item" :class="{ active: !areaId }" @click="areaId = ''">全部</span>
          <span class="item" :class="{ active: item.code === areaId }" v-for="item of area" :key="item.code"
            @click="areaId = item.code">{{ item.name }}</span>
        </span>
      </div>
    </div>
    <div class="content" v-if="stageId === 3">
      <div class="item" v-for="item of list" :key="item.id" @click="showCode=!showCode">
        <img :src="item.cover" />
        <div class="word">{{item.name}}</div>
      </div>
    </div>
    <div class="content" v-if="stageId === 2">
      <div class="item-big" v-for="item of list" :key="item.id" @click="showCode=!showCode">
        <img :src="item.cover" />
        <div class="message">
          <div class="line1">{{item.name}}</div>
          <div class="line2">{{item.regionName}}・{{item.gradeName}}</div>
          <div class="line3">共 {{item.number}} 套</div>
        </div>
      </div>
    </div>
    <div class="qr-code-box" v-show="showCode">
      <div class="qr-code">
        <a-icon type="close" @click="showCode=false" />
        <img src="../assets/xCode.jpeg" v-if="stageId==2">
        <img src="../assets/bCode.png" v-if="stageId==3">
        <p>微信扫一扫,开始学习</p>
      </div>
    </div>
    <div class="page">
      <a-pagination show-quick-jumper show-size-changer :show-total="total => `共 ${total} 条`" :default-current="1"
        :defaultPageSize="9" :total="total" :pageSizeOptions="['9','18','27','36','45']" @change="onChange"
        @showSizeChange="onChange" />
    </div>
  </div>
</template>

<script>
import base from "../api/base"
export default {
  props: {},
  data() {
    return {
      showCode: false,
      subject: [{ id: 3, name: "英语" }],
      area: [],
      subjectId: 3,
      areaId: "",
      stageId: 2,
      page: 1,
      size: 9,
      total: 0,
      list: []
    }
  },
  watch: {
    stageId: function () {
      this.showCode = false
      this.page = 1
      this.getArea()
      this.getList()
    },
    subjectId: function () {
      this.page = 1
      this.getList()
    },
    areaId: function () {
      this.page = 1
      this.getList()
    }
  },
  mounted() {
    // this.$axios
    //   .get("https://xuexiao.yunzuoye.net/tp/public/index.php/mobile/v1/DigitalTextbook/getFilterList")
    //   .then(res => {
    //     this.subject = res.data.subject
    //   })
    this.getArea()
    this.getList()
  },
  methods: {
    getArea() {
      this.$axios.get(`${base}/api/v1/digitalResources/${this.stageId}/3/digitalSearch`).then(res => {
        this.area = res.data
      })
    },
    getList() {
      //"https://xuexiao.yunzuoye.net/tp/public/index.php/mobile/v1/DigitalTextbook/getBookList"
      this.$axios
        .post(`${base}/api/v1/digitalResources`, {
          page: this.page,
          size: this.size,
          stage: this.stageId,
          subjectCode: this.stageId === 3 ? "" : this.subjectId,
          regionCode: this.stageId === 3 ? "" : this.areaId
        })
        .then(res => {
          this.list = res.data.dtoList
          this.total = res.data.totalCount
        })
    },
    onChange(page, pageSize) {
      this.page = page
      this.size = pageSize
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
.main {
  font-family: PingFangSC-Light;
  background: #f5f5f5;
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;
  position: relative;
  .filter-box {
    width: 1200px;
    padding: 25px;
    padding-bottom: 1px;
    background: #fff;
    margin: 8px auto;
    box-sizing: border-box;
    .filter-item {
      font-size: 14px;
      margin-bottom: 25px;
      .title {
        color: rgba(0, 0, 0, 0.8);
        margin-right: 16px;
      }
      .item {
        cursor: pointer;
        margin-right: 24px;
        padding: 5px 8px;
        &.active {
          padding: 5px 8px;
          border-radius: 2px;
          background: #fdebe7;
          color: #ed4014;
        }
      }
    }
  }
  .content {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .item {
      cursor: pointer;
      float: left;
      width: 294px;
      height: 290px;
      background: white;
      padding: 24px;
      margin-right: 8px;
      margin-bottom: 8px;
      box-sizing: border-box;
      img {
        width: 246px;
        height: 184px;
      }
      .word {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    .item-big {
      cursor: pointer;
      float: left;
      width: 395px;
      height: 172px;
      padding: 25px;
      margin-right: 7px;
      margin-bottom: 8px;
      background: white;
      box-sizing: border-box;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      img {
        width: 96px;
        height: 124px;
      }
      .message {
        float: right;
        width: 230px;
        .line1 {
          font-size: 18px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .line2 {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          margin-top: 22px;
        }
        .line3 {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
          margin-top: 8px;
        }
      }
    }
  }
  .qr-code-box {
    position: fixed;
    top: 80px;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .qr-code {
      border-radius: 10px;
      width: 272px;
      height: 338px;
      background: white;
      padding: 16px;
      position: relative;
      top: -100px;
      i {
        cursor: pointer;
        float: right;
        font-size: 20px;
      }
      img {
        width: 242px;
      }
      p {
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
      }
    }
  }
  .page {
    width: 1200px;
    overflow: hidden;
    padding-top: 20px;
    padding-bottom: 50px;
    margin: 0 auto;
    .ant-pagination {
      float: right;
      /deep/ .ant-pagination-item-active {
        border-color: #ed4014;
      }
      /deep/ .ant-pagination-item-active a {
        color: #ed4014;
      }
      /deep/ .ant-pagination-item:hover {
        border-color: #ed4014;
      }
      /deep/ .ant-pagination-item:hover a {
        color: #ed4014;
      }
      /deep/ .ant-pagination-prev:hover .ant-pagination-item-link {
        border-color: #ed4014;
        color: #ed4014;
      }
      /deep/ .ant-pagination-next:hover .ant-pagination-item-link {
        border-color: #ed4014;
        color: #ed4014;
      }
    }
  }
}
</style>