<template>
  <div>
    <a-modal :title="title" :visible="visible" @cancel="close">
      <div class="container">
        <a-input placeholder="请输入激活码" v-model="code" @input="codeChange()" class="mt-8" />
        <span class="msg" v-if="msg">{{ msg }}</span>
        <div class="error" v-else>一个激活码仅限一个账号使用</div>
      </div>
      <template slot="footer">
        <a-button key="back" @click="close"> 取消 </a-button>
        <a-button key="submit" type="primary" @click="submit"> 激活 </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { activeBook } from '@/api/videoResource';
import {buryingPoint} from "@/api/prize";
export default {
  props: {
    visible: {
      default: false
    },
    title: {
      default: '激活教材'
    }
  },
  name: 'ActiveBook',
  components: {},
  data() {
    return {
      code: '',
      msg: ''
    };
  },
  created() {
    const userInfo = localStorage.getItem('user');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).userId;
    }
  },
  methods: {
    close() {
      this.$emit('update:visible');
      this.reset();
      return false;
    },

    // 将字符串的字符全部转换为大写字符
    upperCase(str) {
      let arr = str.split('');
      let newStr = '';
      // 通过数组的forEach方法来遍历数组
      arr.forEach(function (value) {
        if (value >= 'a' && value <= 'z') newStr += value.toUpperCase();
        else newStr += value;
      });
      return newStr;
    },

    codeChange() {
      this.code = this.code.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5\-\—]/g, '');
    },

    async submit() {
      let code = this.upperCase(this.code.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g, ''));
      if (!this.code) {
        this.msg = '请输入激活码';
        return;
      } else {
        const res = await activeBook({
          userId: this.userId,
          bookId: this.bookId,
          activationCode: code
        }).catch(err => {
          this.msg = err.data.msg;
        });
        if (res) {
					// 激活埋点
					await buryingPoint({
						activityCode:  localStorage.getItem('activityCode')||'E_STUDY',
						actionCode: 'ACTIVATION',
						userId: JSON.parse(localStorage.user)?.userId,
						otherParams:'activationCode#'+code
					});
          this.close();
          let that = this;
          setTimeout(() => {
            that.$parent.getDataList();
          }, 300);
        }
      }
    },

    reset() {
      this.code = '';
      this.msg = '';
    }
  }
};
</script>

<style scoped lang="less">
@import url('~@/assets/css/theme.less');
.container {
  .primary-text {
    line-height: 24px;
    color: @primary-color;
  }
  .pointer {
    cursor: pointer;
  }
  .msg {
    position: relative;
    display: block;
    height: 32px;
    line-height: 32px;
    color: @primary-color;
  }
  .error {
    position: relative;
    display: block;
    height: 32px;
    line-height: 32px;
    color: #666;
  }
}

/deep/.save-suc-modal {
  text-align: center;

  .suc-icon {
    width: 60px !important;
    height: 60px !important;
    margin-top: 8px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .p1 {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 30px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .ant-btn {
    margin-bottom: 20px;
  }
}

.ant-btn-primary {
  background-color: #f6492b;
  border: none;
}
</style>
