<template>
  <!-- 音像教材-音视频在线学习 -->
  <div>
    <div class="onlineLearning-container">
      <!-- 左侧目录 -->
      <div class="onlineLearning-left">
        <header>{{sourceName}}</header>
        <p class="onlineLearning-title">
          <img src="@/assets/video/resource.svg" alt="" />
          目录
        </p>
        <div class="item" :class="curIndex == index ? 'active' : ''" v-for="(item, index) in catalogList" :key="index" @click="handleItemClick(item, index)">
          <!-- {{item.catalogIndex}}、 -->
          {{index+1}}.{{ item.catalogName }}
        </div>
      </div>
      <!-- 右侧音视频 -->
      <div class="onlineLearning-right" v-show="curIndex > -1">
        <OnlineVideo :hideDownload="$route.query.resourceId==='64c71e8a551b3c1c3a45ce67'" :list.sync="list" @next="onNext" :audioName.sync="audioName"></OnlineVideo>
      </div>
      <!-- 音视频无资源时占位 -->
      <div class="onlineLearning-right onlineLearning-empty" v-show="curIndex == -1">
        <template>
          <a-empty>
            <span slot="description">请先选择左侧目录</span>
          </a-empty>
        </template>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'
import OnlineVideo from '@/components/onlineLearning.vue' // 音视频播放组件
import { getCatalogList, catalogResource } from '@/api/download'

export default {
  name: 'VideoOnlineLearning',
  components: {
    OnlineVideo,
    Footer
  },
  props: {},
  data() {
    return {
      list: {}, // 给子组件传递的数据
      sourceName: '',
      audioName: '',
      catalogList: [],
      curIndex: -1,
      formData: {
        bookId: '', //书本id
        catalogIdList: [], //目录id集合
        categoryId: '', // 类别
        zjeavSourceId: '', //来源
        resourceType: '', // 资源类型 1:mp3 2:mp4
        name: '', // 搜索名字
        page: 1,
        size: 10,
        sortType: 2 // 排序
      }
    }
  },
  async created() {
    await this.handleGetCatalog()
    // 第一次进入时，默认打开目录第一个进行播放
    // this.formData.bookId = this.catalogList[0].bookId
    // this.formData.catalogIdList = [this.catalogList[0].catalogId]
    // this.handleItemClick(this.catalogList[0], 0)
  },
  methods: {
    onNext(){
      this.curIndex = this.curIndex+1>=this.catalogList.length?0:this.curIndex+1
      this.handleItemClick(this.catalogList[this.curIndex],this.curIndex)
    },
    async handleGetCatalog() {
      const { data } = await getCatalogList({
        // bookId: '62f38bcbce52e51f6b29c4cd'
        bookId: this.$route.query.resourceId
      })
      // console.log(data)
      this.sourceName = data[0].catalogName
      this.catalogList = data[0].childList.map((e)=>{
				e.catalogName =	e.catalogName.slice(2)
				return e
			})
      // console.log(this.catalogList)
    },

    handleItemClick(item, index) {
      // console.log(item, index)
      this.curIndex = index
      this.formData.bookId = item.bookId
      this.formData.catalogIdList = [item.catalogId]
      this.audioName = item.catalogName
      this.getResourceList()
    },

    async getResourceList() {
      const { data } = await catalogResource({
        ...this.formData
      })
      // console.log(data)
      if (data.list && data.list.length > 0) {
        this.list = data.list[0]
      }
    }
  },
  computed: {},
  mounted() {}
}
</script>

<style lang="less" scoped>
*{
  font-family: PingFangSC-Regular;
}
.onlineLearning-container {
  min-height: 507px;
  width: 1200px;
  margin: 16px auto;
  display: flex;
  justify-content: left;
  // background-color: #fff;

  header {
    color: rgba(0,0,0,0.80);
    border-bottom: 1px solid  rgb(238,238,238);
    padding:20px 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    word-break: break-all;

  } 
  .active {
    background-color: rgba(246, 73, 43, 0.1);
    color: #f6492b !important;
  }

  .onlineLearning-left {
    width: 284px;
    height: 509px;
    overflow-y: auto;
    // overflow-x: hidden;
    margin-right: 16px;
    // padding: 16px;
    background-color: #fff;

    .item {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #333333;
      line-height: 16px;
      padding: 16px;
      cursor: pointer;
    }

    .item:hover {
      background-color: rgba(246, 73, 43, 0.1);
      color: #f6492b !important;
    }

    .ant-tree-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      white-space: normal !important;
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      font-size: 14px;
    }

    .onlineLearning-title {
      font-family: PingFangSC-Medium;
      margin-bottom: 0;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      line-height: 16px;
      padding: 20px 16px 16px;
      vertical-align: bottom;
      img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-bottom: 2px;
      }
    }

    .ant-tree.ant-tree-show-line li {
      padding: 4px 0;
    }
  }

  .onlineLearning-left::-webkit-scrollbar {
    display: block;
  }

  .onlineLearning-right {
    width: 900px;
    height: 507px;
    margin: 0 auto;
    background-color: #fff;
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    transform: translateX(150px);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .onlineLearning-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
  }
}
</style>
