<template>
    <div class="aboutUs">
        <img class="aboutUs-bg" src="../assets/aboutUs/aboutus_bg.png" alt="">
        <div class="aboutUs-box">
            <div class="aboutUs-box_title">浙江电子音像出版社有限公司</div>
            <div class="aboutUs-box_address">地址：杭州市体育场路347号</div>
            <div class="aboutUs-box-introduce">简介：
                <span class="aboutUs-box-introduce_text">
                    浙江电子音像出版社是首批经国家新闻出版总署批准成立的电子音像出版社，自建社以来，始终牢记出版使命，坚持将社会效益放在首位，屡次获得中国出版政府奖和中华优秀出版物奖等国家级奖项，多个项目获得国家出版基金资助。与此同时，深耕多媒体教育出版领域，在产品数量、科目涵盖、作者队伍、制作团队以及与教育部门合作等方面，都已经形成了较大的规模、积累了广泛的资源，在业界拥有较高的品牌知名度。近年来，在数字出版方面又进一步做大做深做精，获得了长足的发展，综合实力位居全国同类出版社第一方阵。
                </span>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/Footer'
export default {
    name: 'AboutUs',
    components: {
        Footer
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.aboutUs {
    width: 100%;
    background-color: #fff;

    &-bg {
        width: 100%;
        height: 300px;
    }

    &-box {
        height: 422px;
        padding: 64px 0 0 200px;
        border-bottom: 1px solid #e6e6e6;
        &_title {
            font-size: 28px;
            font-weight: 600;
            color: #333333;
        }

        &_address {
            margin-top: 32px;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
        }

        &-introduce {
            max-width: 1246px;
            margin-top: 24px;
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            text-indent: -3.3em;
            margin-left: 3.3em;
            &_text {
                font-size: 16px;
                font-weight: 400;
                color: #666666;
            }
        }
    }
}
</style>
