import base from './base'
import axios from './request'
import _axios from 'axios'

/**
 * 查询用户书架列表
 */
export const getUserBookList = (userId, params) => {
  return axios.get(`${base}/api/v1/userBookShelf/${userId}`, {
    params
  })
}

/**
 * 根据id查询资源包信息
 */
export const getBookResourceById = params => {
  return axios.get(`${base}/api/v1/catalog/list`, {
    params
  })
}

// 查询目录资源列表
export const getCatalogResource = params => {
  return axios.post(`${base}/api/v1/catalogResource/list`, params)
}

// 更新音像教材的学习量，每次进入教材详情时请求
export const updatePlayCount = params => {
  params.platform = 'WEB'
  return axios.post(`${base}/api/v1/userBookShelf/play`, params)
}

// 根据资源id集合查询资源详情
export const getFileTempUrl = params => {
  return axios.post(`${base}/api/v1/catalogResource/details`, params)
}

export const download = url => {
  return _axios.get(url, { responseType: 'blob' })
}

/**
 * 获取书详情
 */
export const getBookInfo = params => {
  return axios.get(`${base}/api/v1/bookResource`, {
    params
  })
}
export const getYSTBookInfo = params => {
  return axios.get(`${base}/api/v1/book/${params.bookId}/link`, {
    params
  })
}

export const userConfig = params => {
	return axios.get(`${base}/api/v1/userConfig/get`, {
		params
	})
}


/**
 * 激活教材
 */
export const activeBook = params => {
  params.platform = 'WEB'
  return axios.post(`${base}/api/v1/userBookShelf`, params)
}

export const addBook = params =>{
  return axios.post(`${base}/api/v1/userBookShelf/teacher`,params)
}
export const getTextbook = params =>{
  return axios.get(`${base}/api/v1/bookShelf/?stageCode=${params.stage}&gradeCode=${params.grade}&subjectCode=${params.subject}&page=${params.page}&size=${params.size}&userId=${params.userId}&type=${params.type}`)
}