<template>
    <div class="main">
        <Complete />
    </div>
</template>
<script>
import Complete from "../components/Complete.vue"
export default{
    components: { Complete },
}
</script>
<style scoped lang='less'>
 .main{
     width: 600px;
     margin: 0 auto;
     margin-top: 200px;
     /deep/ .Complete .isRequire.area .required-word{
         top: 9px;
     }
 }
</style>
