<template>
  <BeforeLogin>
    <div class="foget">
      <p class="title" v-show="role === 'student'">
        学生注册
        <span
          class="teacher-register"
          @click="role = 'teacher'"
          v-show="step === 0"
          >教师注册 ></span
        >
      </p>
      <p class="title" v-show="role === 'teacher'">
        教师注册
        <span
          class="teacher-register"
          @click="role = 'student'"
          v-show="step === 0"
          >学生注册 ></span
        >
      </p>
      <a-steps :current="step" size="small" v-show="role === 'student'">
        <a-step title="账号注册" />
        <a-step title="完善个人信息" />
      </a-steps>
      <a-steps :current="step" size="small" v-show="role === 'teacher'">
        <a-step title="教师认证" />
        <a-step title="账号注册" />
        <a-step title="完善个人信息" />
      </a-steps>
      <div
        class="teacher-attestation"
        v-show="role === 'teacher' && step === 0"
      >
        <div class="message">认证老师可享受免费资源</div>
        <div class="button" @click="toZjerLogin">之江汇教师认证</div>
        <div class="button-info" @click="step = 1">跳过</div>
      </div>
      <template
        v-if="
          (role === 'student' && step === 0) ||
          (role === 'teacher' && step === 1)
        "
      >
        <a-form :form="form">
          <a-form-item>
            <a-input
              size="large"
              @change="$refs['imgCode'].imgSrc = ''"
              @blur="handleGetImgCode"
              placeholder="请输入手机号"
              v-decorator="[
                'mobile',
                {
                  trigger: 'blur',
                  rules: [
                    { required: true, message: '请输入手机号!' },
                    {
                      message: '请输入正确手机号!',
                      pattern: /^1[3456789]\d{9}$/,
                    },
                  ],
                },
              ]"
            >
              <img
                src="../assets/icon/phone.png"
                class="icon"
                slot="prefix"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <ImgCode :usage="1" ref="imgCode" />
          </a-form-item>
          <a-form-item>
            <div class="input-sms-code">
              <a-input
                placeholder="请输入手机验证码"
                v-decorator="['smsCode', {trigger: 'change', rules: [
                { required: true, message: '请输入验证码!' },{message: '请输入正确验证码!',pattern:/^\d{4}$/}] }]"
              >
                <img src="../assets/icon/captcha.png" class="icon" slot="prefix" />
              </a-input>
              <a-button @click="sendSmsCode" >{{ time ? `(${time}s)` : '获取验证码' }}</a-button>
            </div>
          </a-form-item>
          <a-form-item>
            <a-input-password
              placeholder="设置6-18位登录密码"
              v-decorator="[
                'logonPassword',
                {
                  rules: [
                    { required: true, message: '请输入登录密码!' },
                    { message: '请设置6-18位登录密码!', min: 6, max: 18 },
                    {
                      validator: validateToNextPassword,
                    },
                  ],
                },
              ]"
              type="password"
            >
              <img
                src="../assets/icon/password.png"
                class="icon"
                slot="prefix"
              />
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-input-password
              placeholder="请再次输入密码"
              v-decorator="[
                'confirmPassword',
                {
                  rules: [
                    { required: true, message: '请再次输入密码!' },
                    {
                      validator: compareToFirstPassword,
                    },
                  ],
                },
              ]"
              type="password"
              @blur="handleConfirmBlur"
            >
              <img
                src="../assets/icon/password.png"
                class="icon"
                slot="prefix"
              />
            </a-input-password>
          </a-form-item>
        </a-form>
        <button class="default-btn" @click="submit">
          <a-icon type="loading" v-show="loading" />下一步
        </button>
      </template>
      <Complete
        v-if="
          (role === 'student' && step === 1) ||
          (role === 'teacher' && step === 2)
        "
        :role="role"
        :userId="userId"
        :schoolName="schoolName"
      />
      <p class="bottom" v-show="step === 0">
        已有账号
        <span class="black" @click="$router.push('/loginRegistration?loginStep=1')">立即登录 ></span>
      </p>
    </div>
  </BeforeLogin>
</template>
<script>
import md5 from "blueimp-md5";
import BeforeLogin from "../components/BeforeLogin.vue";
import GetSmsbutton from "../components/getSmsbutton.vue";
import Complete from "../components/Complete.vue"
import { getLoginUrl,login,register,getMobileCode } from "../api/user";
import { mapMutations,mapGetters} from "vuex";
import ImgCode from '@/components/ImgCode'
export default {
  name: "forgetPassword",
  components: { BeforeLogin, GetSmsbutton,Complete,ImgCode },
  data() {
    return {
      form: this.$form.createForm(this, { name: "register" }),
      loading: false,
      role: "student",
      step: 0,
      userId:'',
      schoolName:'',
      time: 0
    };
  },
  computed: {
    ...mapGetters(["getZjerParam"]),
    onLine() {
      return !this.offline;
    },
  },
  mounted: function () {
    console.log(this.getZjerParam)
    if(this.getZjerParam.oauthCode && this.getZjerParam.personId){
      this.role = 'teacher';
      this.step = 1;
    }
  },
  methods: {
    ...mapMutations(["setUser","setZjerParam"]),
    handleGetImgCode() {
      this.$nextTick(() => {
        this.form.validateFields(['mobile'], (error, value) => {
          if (!error) {
            this.$refs['imgCode'].getImgCode(value)
          }
        })
      })
    },
    sendSmsCode() {
      if (this.time) return
      const imgCode = this.$refs['imgCode'].imgCode
      if (!imgCode || !/\d{4}/.test(imgCode)) {
        this.$message.warn('请输入图形验证码')
        return
      }
      this.form.validateFields(["mobile"], (err, value) => {
        if (!err) {
            getMobileCode({
              ...value,
              usage: 1,
              explosionProof: true,
              captchaCode: imgCode
            })
            .then(() => {
              this.form.resetFields('smsCode')
              this.$message.success("发送成功");
              this.time = 60
              let a = setInterval(() => {
                this.time = this.time - 1
                if (!this.time) clearInterval(a)
              }, 1000);
            });
        }
      });
    },
    toZjerLogin() {
      getLoginUrl().then((res) => {
        location.href = res.data;
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("logonPassword")) {
        callback("两次输入的密码不一致!");
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirmPassword"], { force: true });
      }
      callback();
    },
    submit() {
      if (this.loading) return;
      this.form.validateFields((err, value) => {
        if (!err) {
          this.loading = true;
          register({
              mobile: value.mobile,
              smsCode: value.smsCode,
              logonPassword: md5(value.logonPassword).toUpperCase(),
              role: this.role,
              ...this.getZjerParam,
            })
            .then(async (res) => {
              this.loading = false;
              this.userId = res.data;
              this.$message.success("注册成功！");
              this.setZjerParam({});
              login({
                  userName: value.mobile,
                  password: md5(value.logonPassword).toUpperCase()
                })
                .then((res) => {
                  if (res) {
                    this.setUser(res.data);
                    localStorage.user = JSON.stringify(res.data);
                    this.step = this.role === "student" ? 1 : 2;
                  }
                });
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.foget {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 360px;
  .title {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    margin-bottom: 32px;
    font-weight: 600;
  }
  .teacher-register {
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    float: right;
  }
  .teacher-attestation {
    .message {
      margin: 32px 0;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
    }
    .button {
      line-height: 48px;
      text-align: center;
      background: #f73210;
      border-radius: 4px;
      font-size: 20px;
      color: #ffffff;
      border: 0;
      cursor: pointer;
      -webkit-app-region: no-drag;
      margin-bottom: 32px;
    }
    .button-info {
      line-height: 48px;
      text-align: center;
      border: 1px solid #f73210;
      border-radius: 4px;
      font-size: 20px;
      color: #f73210;
      cursor: pointer;
      -webkit-app-region: no-drag;
    }
  }
  .ant-steps {
    margin-bottom: 32px;
    /deep/ .ant-steps-item-process .ant-steps-item-icon {
      border-color: #f73210;
      background-color: #f73210;
    }
    /deep/ .ant-steps-item-finish .ant-steps-item-icon {
      border-color: #f73210;
      background: #f73210;
    }
    /deep/ .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: white;
    }
    /deep/
      .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #f73210;
    }
  }
  .input-sms-code {
    display: flex;
    .ant-input-affix-wrapper {
      width: 232px;
    }
    button {
      width: 112px;
      height: 48px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      margin-left: 12px;
      background-color: #f73210;
    }
  }
  .icon {
    width: 16px;
    height: 16px;
  }
  p.bottom {
    font-size: 16px;
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.45);
    .black {
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      font-weight: 600;
    }
    .right {
      float: right;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.default-btn {
  &.login2 {
    border: 1px solid #f73210;
    background: white;
    color: #f73210;
     width: 360px;
    bottom: 150px;
        margin-top: 16px;
  }
}
.default-btn {
  height: 48px;
  background: #f73210;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  -webkit-app-region: no-drag;
}
</style>
