<template>
  <div class="about-us">
    <div class="about">
      <img alt class="" src="../assets/aboutUs.png" />
    </div>
    <div class="footer-bottom">
      <div class="line2">
        <a href="#" @click="goCopyRight">版权声明</a> |
        <a @click="toOther('http://www.beian.gov.cn/portal/registerSystemInfo')">公安备案号：33010302003168</a> | <a
        @click="toOther('https://beian.miit.gov.cn/#/Integrated/index')">工信部备案号：浙ICP备11047044号</a> |
        <a href="#"> 出版物经营许可证：浙批-300476号
        </a> | <a href="#">网络出版服务许可证：浙-003号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  methods: {
    goCopyRight() {
      this.$router.push('/copyRight')
    },
    toOther(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.about-us {
  height: calc(~"100% - 80px");
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  .about {
    width: 1200px;
    margin: 16px auto;
    background-color: #fff;
    border-radius: 8px;
    flex: 1;
    > img {
      display: block;
      width: 100%;
    }
  }
  .footer-bottom {
    flex-shrink: 0;
    display: inline-block;
    height: 62px;
    background: #333;
    text-align: center;
    .line2 {
      display: inline-block;
      width: 1200px;
      line-height: 62px;
      font-size: 14px;
      color: #999;
      a {
        color: #999;
      }
    }
  }
}
</style>
