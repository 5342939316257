import axios from 'axios'
import router from '../router'
import md5 from "blueimp-md5";
import base from './base';
import { notification } from 'ant-design-vue'
import store from '../store'
import Cookies from 'js-cookie'


function encrytred(method, url, t, body, query, token) {
    let result = '';
    result += method.toUpperCase();
    url = url.replace(base ,"")
    result += url;
    if (query) {
        Object.keys(query).forEach((item, index) => {
            if (query[item]) {
                result += `${index == 0 ? '?' : '&'}${item}=${query[item]}`
            }
        })
    }
    if (body) {
        result += JSON.stringify(body);
    }
    if(token){
        token = 'Bearer '+token
        result += token
    }

    result += t;

    result += "em69cictffdp-74td5-o6f6f7idw3v-ebs4e-hfie8bz7hctfpex-2ec9r34cg5n"; //key


    // console.log(method.toUpperCase(),url,token,t)
    return md5(result)
}
var instance = axios.create();
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.interceptors.request.use(config => {
    if (!navigator.onLine) {
        notification.error({
            message: '请连接网络使用',
            duration: 3
        });
        return Promise.reject('请连接网络使用')
    }
    const token = localStorage.user ? JSON.parse(localStorage.user).token : '';
    token && (config.headers.Authorization = 'Bearer ' + token);
    let t = new Date().getTime()
    config.headers["EAV-Timestamp"] = t
    config.headers["EAV-Sign"] = encrytred(config.method,config.url,t+"",config.data,config.params,token)
    if(token) config.headers["EAV-UserId"] = JSON.parse(localStorage.user).userId
    if (config.method === "get") {
        let result = {}
        for (const key in config.params) {
            if (config.params[key] || config.params[key]===0 ||config.params[key]===false) {
                result[key] = config.params[key]
            }
        }
        config.params = result
    }
    return config
}, error => {
    return Promise.error(error);
})
instance.interceptors.response.use(
    (res) => {
        if(res.status !== 200 || (typeof res.data.code === 'number' && res.data.code!==200) ){
            res.data.msg&&notification.error({
                message: res.data.msg,
                duration: 3
            });
            return  Promise.reject(res)

        }
        return Promise.resolve(res)
    },
    error => {
        const { response } = error;
        if (response && error.response.status) {
            switch (error.response.status) {
                // 401: 未登录
                case 401:
									localStorage.clear()
									store.commit('setUser', {})
									Cookies.remove('commonName', {domain: 'zjeav.com'})
									setTimeout(() => {
										router.replace({
											name: "loginRegistration",
											query: {
												loginStep: 1,
												redirect: router.currentRoute.fullPath
											}
										});
									}, 1000);
                    break;

                // 403 token过期
                // 清除本地token和清空vuex中token对象
                // 跳转登录页面
                case 403:
                    store.commit('setUser', {})
                    notification.error({
                        message: response.data.msg,
                        duration: 3
                    });
                    // 清除token
                    localStorage.clear()
									Cookies.remove('commonName', { domain: 'zjeav.com' })
									setTimeout(() => {
										router.replace({
											name: "loginRegistration",
											query: {
												loginStep: 1,
												redirect: router.currentRoute.fullPath
											}
										});
									}, 1000);
                    break;

                // 404请求不存在
                case 404:
                    notification.error({
                        message: '网络请求不存在',
                        duration: 3
                    });
                    break;
                // 其他错误，直接抛出错误提示
                default:
                    console.log(error)
                    notification.error({
                        message: error.response.data.msg,
                        description: error.response.data.description || error.response.data.msg,
                        duration: 5
                    });
            }
            return Promise.reject(error.response);
        }
        else {
            // 处理断网的情况
            if (!window.navigator.onLine) {

            } else {
                return Promise.reject(error);
            }
        }
    }
)
export default instance;
export {encrytred}
