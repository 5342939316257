<template>
  <div class="img-code">
    <a-input
      v-model="imgCode"
      class="code"
      placeholder="请输入图形验证码"
      style="margin-right: 12px;"
    />
    <img v-show="imgSrc" :src="imgSrc" alt="" class="img" @click="getImgCode">
    <span v-show="!imgSrc" class="tips">输入手机号获取图形验证码</span>
  </div>
</template>

<script>
import {getuserImg,getMobileCode,checkMoblie} from "@/api/user"
export default {
  name: 'ImgCode',
  props: [
    'usage'
  ],
  data() {
    return {
      imgSrc: '',
      imgCode: '',
      mobile: ''
    }
  },
  methods: {
    async getImgCode({ mobile }) {
      // 重新获取验证码时不置空
      if (mobile) {
        this.imgSrc = ''
      }
      this.mobile = mobile || this.mobile
      let res = await checkMoblie({ mobile: this.mobile })
      if (res.data && +this.usage === 1) {
        this.$message.error('该手机号已注册！')
        return
      } else if (!res.data && +this.usage === 2) {
        this.$message.error('该手机号未注册！')
        return
      }
      res = await getuserImg({
        usage: this.usage,
        mobile: this.mobile,
        t: new Date().getTime()
      })
      this.imgSrc = res
    },
  }
}
</script>

<style lang="less" scoped>
.img-code {
  display: flex;
  justify-content: space-between;

  .code {
    max-width: 232px;
    flex: 1;
    flex-shrink: 0;
  }

  .img, .tips {
    display: block;
    width: 106px;
    height: 48px;
    line-height: 24px;
  }
}
</style>
