<template>
    <div class="course">
        <img :src="require('@/assets/bz2.jpg')" alt="">
    </div>
  </template>
  
  <script>
  export default {
    name: 'course',
    methods: {
    }
  }
  </script>
  
  <style lang="less" scoped>
  .course{
    width: 1200px;
    margin: 40px auto 100px auto;
    >img{
        width: 100%;
        height: 1344px;
    }
  }
  </style>
  