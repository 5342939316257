<template>
  <div class="Footer">
    <div class="Footer-main">
      <div class="Footer-main-left">
        <div class="Footer-company">
          <div class="Footer-company-label">业务指导：</div>
          <div class="Footer-company-value">浙江省教育技术中心</div>
        </div>
        <div class="Footer-company">
          <div class="Footer-company-label">主办单位：</div>
          <div class="Footer-company-value">浙江出版传媒股份有限公司</div>
        </div>
        <div class="Footer-company Footer-company--block">
          <div class="Footer-company-label">实施单位：</div>
          <div class="Footer-company-value">浙江出版传媒股份有限公司教材中心</div>
        </div>
      </div>
      <div class="Footer-main-right">
        <div class="us">
          <div class="us-title">
            联系我们
          </div>
          <div class="us-main">
            <img src="@/assets/telephone.svg" class="us-icon">
            <div class="us-item">
              <div class="us-item-text">400-685-1161</div>
              <div class="us-item-info us-item-info--small">工作日09:00-21:00 节假日09:00-21:00</div>
            </div>
          </div>
          <div class="us-main">
            <img src="@/assets/email.svg" class="us-icon">
            <div class="us-item">
              <div class="us-item-text">zjvideo@126.com</div>
            </div>
          </div>
        </div>
        <div class="us">
          <div class="us-title" @click="goAboutUs" style="cursor: pointer;margin-bottom: 16px;">
            关于我们
          </div>
          <div class="us-main">
            <div class="us-item">
              <img class="us-item-img" @click="goAboutUs" src="../assets/footer_logo.svg" alt="">
              <div class="us-item-info" style="cursor: pointer;" @click="goAboutUs">了解更多></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Footer-bottom">
      <div class="Footer-bottom-item" :class="{ 'Footer-bottom-item--clickable': item.url }" @click="openUrl(item)"
        v-for="item in PublicWebData">
        {{ item.label }}
        <template v-if="item.value">{{ item.value }}</template>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Footer',
  components: {},
  props: [],
  data() {
    return {
      rights: [
        {
          label: "联系方式"
        },
        {
          label: "周一至周五  9:00-21:00",
          time: `周六至周日  9:00-22:00`
        },
        {
          img: require("../assets/icon_kefu.png"),
          label: "在线客服",
          labelHover: true,
          labelB: "4006851161",
          labelBHover: true
        },
        {
          img: require("../assets/icon_phone.png"),
          label: "400-685-1161",
          labelB: "zjvideo@126.com"
        }
      ],
      PublicWebData: [
        {
          label: "版权声明",
          url: '/copyRight'
        },
        {
          label: "公安备案号:",
          value: "33010302003168",
          url: "https://www.beian.gov.cn/portal/registerSystemInfo"
        },
        {
          label: "工信部备案号:",
          value: "浙ICP备11047044号",
          url: "https://beian.miit.gov.cn/#/Integrated/index"
        },
        {
          label: "出版物经营许可证:",
          value: "浙批-300476号 "
        },
        {
          label: "网络出版服务许可证:",
          value: "浙-003号"
        }
      ]
    }
  },
  mounted() { },
  methods: {
    publishLience() {
      this.$emit("toOpen", true)
    },
    goAboutUs() {
      this.$router.push('/about-us')
    },
    openUrl(item) {
      if (item?.url?.startsWith('http')) {
        window.open(item.url)
      } else {
        this.$router.push(item?.url)
      }
    },
    open(item, index) {
      if (item.label == "在线客服" && index != 1) {
        window.qimoChatClick()
      } else if (item.labelB == "4006851161" && index == 1) {
        // window.open("tencent://message/?uin=3281866819")
        window.open('https://wpa1.qq.com/LyIyluhX?_type=wpa&qidian=true')
      }
    }
  },
  computed: {},
  watch: {}
}
</script>

<style lang="less" scoped>
.Footer {
  background: #fff;
  padding-top: 48px;
  backdrop-filter: blur(8px);

  &-main,
  &-bottom {
    width: 1200px;
    margin: 0 auto;
  }

  &-main {
    display: flex;

    &-left {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      white-space: nowrap;
      flex: 1;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   left: 100%;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   background: #E6E6E6;
      //   height: 124px;
      //   width: 1px;
      // }
    }

    &-right {
      display: flex;
      flex: 1;
    }
  }

  &-company {
    margin-right: 64px;
    display: inline-block;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &-label {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      margin-bottom: 16px;
      line-height: 1;
    }

    &-value {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 1;
    }
  }

  &-bottom {
    border-top: 1px solid #E6E6E6;
    margin-top: 48px;
    padding: 16px 0;
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 400;
    color: #999999;

    &-item {
      padding: 0 16px;
      position: relative;
      transition: opacity .3s;

      &--clickable {
        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 14px;
          background: #E6E6E6;
        }
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .us {
    flex: 1;
    margin-left: 96px;

    &-title {
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      color: #333333;
    }

    &-main {
      display: flex;
      align-items: flex-start;
    }

    &-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &-item {
      flex: 1;

      &-text {
        line-height: 1;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
      }
      &-img{
        cursor: pointer;
        margin-bottom: 14px;
      }
      &-info {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        white-space: nowrap;
        line-height: 1;

        &--small {
          font-size: 12px;
          margin: 7px 0 17px;
        }
      }
    }
  }
  .us:nth-child(2) {
    margin-left: 173px;
  }
}
</style>