<template>
  <userLayout>
    <div class="content">
      <a-tabs default-active-key="2" >
        <a-tab-pane key="1" tab="数字教材"></a-tab-pane>
        <a-tab-pane key="2" tab="数字资源" force-render>
          <div class="qr-code">
            <img src="../assets/xCode.jpeg">
            <p>初中</p>
        </div>
        <div class="qr-code">
            <img src="../assets/bCode.png">
            <p>高中</p>
        </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </userLayout>
</template>
<script>
import userLayout from "../components/userLayout.vue";
import complete from "../components/Complete.vue";
export default {
  components: { userLayout, complete },
};
</script>
<style scoped lang='less'>
.content {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  background: #f5f5f5;
  /deep/ .ant-tabs-bar{
      background: white;
  }
  /deep/ .ant-tabs-tab{
      line-height: 32px;
      font-size: 16px;
  }
  /deep/.ant-tabs-nav .ant-tabs-tab-active {
    color: #f73210;
  }
  /deep/ .ant-tabs-nav .ant-tabs-tab:hover {
    color: #f73210;
  }
  /deep/.ant-tabs-ink-bar {
    background: #f73210;
  }
  .qr-code{
      display: inline-block;
      background: white;
      padding: 15px;
      margin-left: 48px;
      margin-top: 48px;
      img{
          width: 242px;
          height: 234px;
      }
      p{
              text-align: center;
              font-size: 18px;
color: rgba(0,0,0,0.80);
          }
  }
}
</style>